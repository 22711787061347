import React, { useEffect, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Loader from '../Layouts/Loader'
import styled from 'styled-components'
import classNames from 'classnames'
import moment from 'moment'
import _ from 'lodash'
import ChatHeader from './ChatHeader'
import ChatInputSection from './ChatInputSection'
import ChatMedia from './ChatMedia'
import socket from '../../utils/socket'
import {
    readMessage,
    addNewMessageInEnd,
    updateMessageAction,
    getMessages,
    updateIsMessagesLoading,
    sendTextMessage,
    updateUsersLastMessage,
    setIsMoreMessagesLoading,
    loadMoreMessages,
    updateUserList,
    showBanner
} from '../../actions/chatActions'
import Linkify from 'react-linkify'
import punycode from 'punycode/'
import MessageSettings from './MessageSettings'
import { openCopyToClipboardToastWithOffset } from '../../actions/copyToClipboardAction'
import { ROLE_MODEL, ROLE_CONTENT_MANAGER, ROLE_USER } from '../../utils/constant'
import { getAllPromotionOffers } from '../../actions/PromotionActions'

const MainDiv = styled.div`
    height: ${props => props.isLoading === 'true' ? '100%' : 'auto'};
    position: relative;

    .pointer-event-none {
        pointer-events: none;
    }
`

const StyledDiv = styled.div`
    margin: 0 20px;
    display: flex;
    flex-direction: column;

    .message {
        padding: 10px;
        border-radius: 10px;
        margin-bottom: 10px;
        max-width: 90%;
    }

    .receiver-message {
        background-color: ${props => props.chatReceiverMessageBackgroundColor};
        color: ${props => props.chatReceiverMessageFontColor};
        align-self: flex-start;
        padding: 0;
        box-shadow: 0px 0px 1px 1px;
        position: relative;

        a {
            color: ${props => props.chatReceiverMessageFontColor} !important;
            text-decoration: underline !important;
            cursor: pointer;
        }
    }

    .sender-message {
        background-color: ${props => props.chatSenderMessageBackgroundColor};
        color: ${props => props.chatSenderMessageFontColor};
        align-self: flex-end;
        position: relative;

        a {
            color: ${props => props.chatSenderMessageFontColor} !important;
            text-decoration: underline !important;
            cursor: pointer;
        }
    }

    .tips {
        background-color: #9cff88;
        color: #000000;
        border: none;
    }

    .price {
        font-size: 12px;
        margin-right: 10px;
    }

    .message.receiver-message.tips:after {
        content: '';
        display: block;
        left: -5px;
        top: 0;
        background-color: #9cff88;
        position: absolute;
        width: 20px;
        height: 10px;
        transform: skew(35deg, 8deg) rotate(-8deg);
        border-bottom: 0px;
        border-right: 0px;
        box-shadow: 0px 0px 1px 1px;
    }

    .message.sender-message:after {
        content: '';
        display: block;
        right: -3px;
        top: 0;
        background-color: ${props => props.chatSenderMessageBackgroundColor};
        position: absolute;
        width: 20px;
        height: 10px;
        transform: skew(-49deg, 7deg) rotate(-8deg);
        border-bottom: 0px;
        border-right: 0px;
    }

    .message.sender-message.tips:after {
        background: #9cff88;
        color: #000000;
    }

    .message.receiver-message:after {
        content: '';
        display: block;
        left: -5px;
        top: 0;
        background-color: ${props => props.chatReceiverMessageBackgroundColor};
        position: absolute;
        width: 20px;
        height: 10px;
        transform: skew(35deg, 8deg) rotate(-8deg);
        border-bottom: 0px;
        border-right: 0px;
        box-shadow: 0px 0px 1px 1px ${props => props.chatReceiverMessageFontColor};
    }

    .main {
        padding: 10px;
        z-index: ${props => props.zIndex};
        position: relative;
        background: ${props => props.chatReceiverMessageBackgroundColor};
        border-radius: 10px;
    }

    .message.receiver-message.tips .main {
        background: #9cff88;
    }

    .msg {
        word-wrap: break-word;
        white-space: pre-wrap;
    }

    .fa-ellipsis-v {
        color: ${props => props.messageEllipsisColor};
        cursor: pointer;
        right: -20px;
        padding: 10px;
        bottom: 8px;
    }

    &.chat-messages {
        min-height: 100dvh;
        max-height: max-content;
        justify-content: end;
    }

    @media(max-width: 576px){
        padding-bottom: 43px;
    }
`

const TimeSpan = styled.span`
    font-size: 10px;

    .sender {
        color: ${props => props.chatSenderMessageFontColor};
    }

    .receiver {
        color: ${props => props.chatReceiverMessageFontColor};
    }
`

const PriceText = styled.span`
    text-decoration: line-through;
    text-decoration-color: ${props => props.textDecorationColor}; 
    textDecorationThickness: 2px;
`

function Messages(props) {
    const { chat, auth, colorScheme, promotion } = props
    const {
        chatSenderMessageBackgroundColor,
        chatSenderMessageFontColor,
        chatSenderButtonBackgroundColor,
        chatSenderButtonFontColor,
        chatReceiverMessageBackgroundColor,
        chatReceiverMessageFontColor,
        chatReceiverButtonBackgroundColor,
        chatReceiverButtonFontColor,
        chatUnlockButtonBackgroundColor,
        chatUnlockButtonFontColor,
        messageEllipsisColor } = colorScheme

    const [canChat, setCanChat] = useState(false)
    const [chatStartTime, setChatStartTime] = useState(null)
    const [chatEndTime, setChatEndTime] = useState(null)
    const [chatEndTimeWithDelay, setChatEndTimeWithDelay] = useState(null)
    const [countDownDuration, setCountDownDuration] = useState(0)
    const { isAdmin, role, _id } = auth.user
    const { content_color, content_font_color, enable_promotion, promotion_settings } = auth.appSettings
    const { isMessagesLoading, selectedUserId, userProfileInfo, newChatMessage } = chat
    const selectedUserProfileDetails = userProfileInfo[selectedUserId]
    const [isPopupOpen, setIsPopupOpen] = useState(false)
    const [activeSettingForMessageIndex, setActiveSettingForMessageIndex] = useState(-1)
    const { lockedContentPromotion } = promotion
    const isMassMessagePromotionActive = enable_promotion &&
        lockedContentPromotion &&
        lockedContentPromotion.type === 'LOCKED_CONTENT' &&
        ['EXCLUSIVE_CONTENT_AND_MASS_MESSAGE', 'MASS_MESSAGE'].includes(lockedContentPromotion.applicable_to) ? true : false
    const user_id = props.auth.user.isAdmin === true ? props.match.params.id : props.chat.selectedUserId
    const messages = _.isEmpty(newChatMessage[user_id]) === false ? newChatMessage[user_id].messages : []
    const [tempLoading, setTempLoading] = useState(messages.length === 0 ? true : false)
    const { currentMessagesPage = 0, shouldLoadMoreMessages = false, isMoreMessageLoading = false } = props.chat.newChatMessage[props.chat.selectedUserId] || {}
    let messageScrollPosition = 0

    const loadOldMessages = async () => {
        recordScrollPosition()
        const modelId = props.auth.appSettings.model_id
        let data = {
            userId: chat.selectedUserId,
            modelId: modelId,
            pageNum: currentMessagesPage + 1
        }
        if (props.auth.user.isAdmin === false) {
            const userDomain = props.chat.userList.filter(obj => obj._id === chat.selectedUserId)
            data.domain = userDomain[0].domain
            data.email = props.auth.user.email
        }
        await props.loadMoreMessages(data, auth.user.isAdmin)
        restoreScrollPosition()
    }

    const handleScroll = (e) => {
        e.preventDefault()
        if (!shouldLoadMoreMessages) {
            e.target.removeEventListener('scroll', handleScroll)
            return
        }

        let winScroll = e.target.scrollTop
        let height = e.target.scrollHeight - e.target.clientHeight
        const scrolled = winScroll / height
        if (scrolled === 0) {
            e.target.removeEventListener('scroll', handleScroll)
            loadOldMessages()
        }
    }

    useEffect(() => {
        if (currentMessagesPage >= 1) {
            const msgContainer = document.getElementById('message-list')
            msgContainer.addEventListener('scroll', handleScroll)
        }
        // TODO: add event lister if current-message-page is 1
    }, [currentMessagesPage, selectedUserId, shouldLoadMoreMessages])

    const recordScrollPosition = () => {
        const objDiv = document.getElementById('message-list')
        messageScrollPosition = objDiv.scrollHeight + 10
    }

    const restoreScrollPosition = () => {
        const objDiv = document.getElementById('message-list')
        const currentScrollPosition = objDiv.scrollHeight - messageScrollPosition
        setTimeout(() => {
            objDiv.scrollTop = currentScrollPosition
        }, 0)
    }

    useEffect(() => {
        if (props.auth.user.isAdmin === false && props.chat.userList.length === 1) {
            props.showBanner(false)
        }
    }, [props.chat.userList])

    // show announcement banner when leave chat page
    useEffect(() => {
        return () => {
            props.showBanner(true)
        }
    }, [])

    const receiveMessage = () => {
        socket.on('MESSAGE_RECEIVE', (msg) => {
            const userIdString = _id.toString()
            const { selectedUserId, selectedModelId } = props.chat
            const { receiverId, senderId } = msg
            const receiverIdString = receiverId.toString()
            const senderIdString = senderId.toString()

            let data = {
                userId: selectedUserId
            }
            if ((isAdmin)) {
                data.modelId = props.auth.appSettings.model_id ? props.auth.appSettings.model_id : selectedModelId
            }

            if (role === ROLE_USER) {
                const userDomain = props.chat.userList.filter(obj => obj._id === selectedUserId)
                data.domain = userDomain[0].domain
                data.email = props.auth.user.email
            }
            const userId = props.auth.isAdmin ? (msg.fromAdmin === true ? receiverId : senderId) : (msg.fromAdmin === true ? senderId : receiverId)
            // add message from user while message sender is user
            addMessage(msg, userId)

            const lastMessage = msg.type === 'tips'
                ? isAdmin === true ? `<user_name> just tipped you ${msg.message}!` : `You just tipped <model_name> ${msg.message}`
                : msg.type === 'GO_LIVE_STREAM' && msg.message === '' ? 'Go Live Stream with <user_name> has ended.' : msg.message
            console.log('receive message')
            if (msg.type !== 'system') {
                props.updateUsersLastMessage({ user_id: userId, message: lastMessage, type: msg.type, isAdmin: msg.fromAdmin })
            }

            let shouldReadMessage = false
            // Read messages to all the admins if the message is to/from the same user as the open chat
            if (isAdmin && [receiverIdString, senderIdString].includes(selectedUserId)) {
                shouldReadMessage = true
            }

            // Read messages to users if the message is sent/received by them
            if (isAdmin === false && [receiverIdString, senderIdString].includes(userIdString)) {
                shouldReadMessage = true
            }

            if ([ROLE_MODEL, ROLE_CONTENT_MANAGER, ROLE_USER].includes(role) && shouldReadMessage === true) {
                props.readMessage(data, isAdmin)
            }
        })
    }

    const addMessage = (message, selectedUserId) => {
        if (message && !message.isRotated) {
            props.addNewMessageInEnd(message, selectedUserId)
            scrollToBottom()
        }
    }

    useEffect(() => {
        const setupSocketConnection = () => {
            if (socket.disconnected) {
                socket.connect()
            }

            let domain = ''
            if (!props.auth.isAdmin) {
                const userList = props.chat.userList
                domain = userList.find(user => user._id === selectedUserId)?.domain
            }
            const roomData = {
                roomId: props.auth.user.isAdmin ? selectedUserId : props.auth.user._id,
                userId: props.auth.user._id,
                isUniversal: props.auth.isAdmin ? false : domain !== window.location.hostname,
                channel: domain,
                isAdmin: props.auth.user.isAdmin,
                email: props.auth.user.email
            }
            socket.emit('JOIN_ROOM', roomData)

            if (isAdmin) {
                socket.emit('GET_ONLINE_USER_LIST')
                socket.emit('ADMIN_IN_USER_CHAT', { adminId: props.auth.user._id, userId: selectedUserId })
            } else if (typeof props.auth.user._id !== 'undefined' && props.auth.user.isAdmin === false && props.auth.user.role !== 'proxy_user') {
                const data = { userId: props.auth.user._id }
                socket.emit('USER_ONLINE', data)
            }
            receiveMessage()
        }

        setupSocketConnection()

        return () => {
            socket.off('MESSAGE_RECEIVE')
        }
    }, [selectedUserId])

    useEffect(() => {
        if ((!props.auth.isAdmin || props.match.params.id !== undefined) && isMessagesLoading === false) {
            if (chatMessages.length === 0) {
                props.updateIsMessagesLoading(true)
                const userId = props.match.params.id || props.chat.selectedUserId
                const modelId = props.auth.appSettings.model_id
                let data = {
                    userId: props.auth.user.isAdmin === true ? userId : props.chat.selectedUserId,
                    selectedUserId: userId,
                    selectedModelId: modelId,
                    pageNum: 1,
                    role: props.auth.user.role
                }
                if (props.auth.user.isAdmin === false) {
                    const userDomain = props.chat.userList.filter(obj => obj._id === userId)
                    data.domain = userDomain[0].domain
                    data.email = props.auth.user.email
                }
                props.getMessages(data, props.auth.user.isAdmin)
            }
        }
        updateMessage()
        props.getAllPromotionOffers()

        // Improves UX, when user come back again on chat page
        setTimeout(() => {
            setTempLoading(false)
            scrollToBottom()
        }, 500)
    }, [])

    // Scroll to bottom whenever messages change
    const scrollToBottom = () => {
        const objDiv = document.getElementById('message-list')
        if (objDiv) {
            setTimeout(() => {
                objDiv.scrollTop = objDiv.scrollHeight
            }, 0)
        }
    }

    const chatMessages = useMemo(() => {
        const userId = props.chat.selectedUserId || props.match.params.id
        return _.isEmpty(newChatMessage[userId]) === false ? newChatMessage[userId].messages : []
    }, [messages])

    useEffect(() => {
        scrollToBottom()
    }, [messages])

    const handleTextMessageSend = (data, callback) => {
        props.sendTextMessage(data, (status) => {
            if (status === false) {
                props.history.push('profile/add-new-payment-method')
            } else {
                callback()
            }
        })
    }

    const getDiscountPrice = (amount) => {
        let promotionPercentage = 0
        if (isMassMessagePromotionActive) promotionPercentage = lockedContentPromotion.discount_percentage

        if (promotionPercentage === 0) {
            return amount
        }
        return Math.ceil(amount * (100 - promotionPercentage) / 100)
    }

    const updateMessage = () => {
        socket.on('UPDATE_MESSAGE', (msg) => {
            const messageId = _.get(msg, 'messageId', false)
            if (messageId !== false) {
                msg._id = messageId
            }
            const userId = msg.fromAdmin === true ? msg.receiverId : msg.senderId
            props.updateMessageAction(msg, userId)
        })
    }

    const componentDecorator = (href, text, key) => (
        <a href={href} key={key} target='_blank' rel='noopener noreferrer'>
            {punycode.toASCII(text)}
        </a>
    )

    const copyToClipboard = (value) => {
        if (auth.user.isAdmin === true) {
            let offsetForToastMessage = ''
            if (window.innerWidth > 991) {
                const messageContainer = document.getElementById('message-list')
                const { x: messageContainerStartingPoint, width } = messageContainer.getBoundingClientRect()
                offsetForToastMessage = `${(width / 2 + messageContainerStartingPoint)}px`
            }
            if (navigator.clipboard) {
                navigator.clipboard.writeText(value)
                props.openCopyToClipboardToastWithOffset('copy', offsetForToastMessage)
            }
        }
    }
    return <MainDiv isLoading={(isMessagesLoading || tempLoading) ? 'true' : 'false'}>
        {_.isEmpty(selectedUserId) === false &&
            <ChatHeader
                colorScheme={colorScheme}
                handleTextMessageSend={handleTextMessageSend}
                scrollToBottom={scrollToBottom}
                canChat={canChat}
                setCanChat={setCanChat}
                chatStartTime={chatStartTime}
                setChatStartTime={setChatStartTime}
                chatEndTime={chatEndTime}
                setChatEndTime={setChatEndTime}
                chatEndTimeWithDelay={chatEndTimeWithDelay}
                setChatEndTimeWithDelay={setChatEndTimeWithDelay}
                countDownDuration={countDownDuration}
                setCountDownDuration={setCountDownDuration}
            />
        }
        {isMessagesLoading || tempLoading
            ?
            <div className='text-center d-flex align-itens-center justify-content-center h-100'>
                <Loader style={{ position: 'fixed', top: '50%' }} loading={isMessagesLoading || tempLoading} size={10} />
            </div>
            :
            <StyledDiv
                className='chat-messages mt-2'
                contentColor={content_color}
                contentFontColor={content_font_color}
                chatSenderMessageBackgroundColor={chatSenderMessageBackgroundColor}
                chatSenderMessageFontColor={chatSenderMessageFontColor}
                chatReceiverMessageBackgroundColor={chatReceiverMessageBackgroundColor}
                chatReceiverMessageFontColor={chatReceiverMessageFontColor}
                messageEllipsisColor={messageEllipsisColor}
                zIndex={isPopupOpen ? '' : '1'}
            >
                {isMoreMessageLoading &&
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Loader loading={isMoreMessageLoading} size={10} />
                    </div>
                }

                {chatMessages.length > 0 && chatMessages.map((message, i) => {
                    const isSenderAdmin = isAdmin ? message.fromAdmin : !message.fromAdmin
                    const isTipsMessage = message.type === 'tips'
                    let chatMessage = message.message
                    let tipMessage = message.tipMessage

                    if (isAdmin === true && message.fromAdmin === false && message.processing === true) {
                        return <></>
                    }
                    if (isTipsMessage) {
                        const receiverName = _.get(selectedUserProfileDetails, 'name', isAdmin ? 'model' : 'user')
                        const tipSender = isAdmin ? receiverName : 'You'
                        const tipReceiver = isAdmin ? 'you' : receiverName
                        chatMessage = `${tipSender} just tipped ${tipReceiver} ${message.message}!`
                    }

                    if (message.type === 'GO_LIVE_STREAM') {
                        chatMessage = `Go Live Stream with ${selectedUserProfileDetails ? selectedUserProfileDetails.name : 'user'} has ended.`
                    }

                    if (message.type !== 'system') {
                        return (
                            <div
                                id={message._id}
                                key={message._id}
                                className={
                                    classNames('message', {
                                        'receiver-message': !isSenderAdmin,
                                        'sender-message': isSenderAdmin,
                                        'tips': isTipsMessage,
                                        'locked': message.isLocked === 'locked'
                                    })
                                }
                            >
                                <div
                                    className={
                                        classNames('', {
                                            'main': !isSenderAdmin
                                        })
                                    }
                                >
                                    {['photo', 'video', 'gallery'].includes(message.type) &&
                                        <ChatMedia
                                            buttonBackgroundColor={isSenderAdmin ? chatSenderButtonBackgroundColor : chatReceiverButtonBackgroundColor}
                                            buttonFontColor={isSenderAdmin ? chatSenderButtonFontColor : chatReceiverButtonFontColor}
                                            unlockButtonBackgroundColor={chatUnlockButtonBackgroundColor}
                                            unlockButtonFontColor={chatUnlockButtonFontColor}
                                            message={message}
                                            setIsPopupOpen={setIsPopupOpen}
                                            colorScheme={colorScheme}
                                            index={i} />
                                    }
                                    {!_.isEmpty(chatMessage) && <>
                                        <Linkify componentDecorator={componentDecorator}>
                                            <span onClick={() => copyToClipboard(chatMessage)} className='msg'>{chatMessage}</span>
                                            {isTipsMessage && !_.isEmpty(tipMessage) && <>
                                                <br />
                                                <span onClick={() => copyToClipboard(tipMessage)} className='msg'>{tipMessage}</span>
                                            </>}
                                        </Linkify>
                                        <br />
                                    </>
                                    }
                                    <div className='d-flex justify-content-between align-items-baseline'>
                                        {(message.isLocked === 'locked' || message.isLocked === 'unlocked') && isAdmin &&
                                            role !== 'live_stream_manager' &&
                                            <span className='price'>
                                                <i className={
                                                    classNames('fas mr-2', {
                                                        'fa-lock': message.isLocked === 'locked',
                                                        'fa-unlock': message.isLocked === 'unlocked'
                                                    })
                                                }></i>
                                                {message.promotion_price ?
                                                    <>
                                                        <PriceText textDecorationColor={promotion_settings.price_strike_through_color}>
                                                            ${message.amount}
                                                        </PriceText> ${message.promotion_price}
                                                    </> :
                                                    <>
                                                        {isMassMessagePromotionActive === true && message.isMassMessage === true && message.isLocked === 'locked' ?
                                                            <>
                                                                <PriceText textDecorationColor={promotion_settings.price_strike_through_color}>
                                                                    ${message.amount}
                                                                </PriceText> ${getDiscountPrice(message.amount)}
                                                            </>
                                                            :
                                                            <>${message.amount} </>
                                                        }
                                                    </>
                                                }
                                            </span>
                                        }
                                        <TimeSpan
                                            className={
                                                classNames({
                                                    'ml-auto': isSenderAdmin,
                                                    'receiver': !isSenderAdmin,
                                                    'sender': isSenderAdmin,
                                                    'tips': isTipsMessage
                                                })
                                            }
                                            chatSenderMessageFontColor={chatSenderMessageFontColor}
                                            chatReceiverMessageFontColor={chatReceiverMessageFontColor}
                                        >
                                            {moment(message.created).calendar()}
                                        </TimeSpan>
                                    </div>
                                </div>
                                <MessageSettings
                                    message={message}
                                    indexInfo={[message._id, activeSettingForMessageIndex]}
                                    onClick={(i) => setActiveSettingForMessageIndex(i)}
                                />
                            </div>
                        )
                    }
                })}
            </StyledDiv>
        }
        <span className={
            classNames({
                'pointer-event-none': isMessagesLoading || tempLoading
            })
        }>
            {
                role !== 'live_stream_manager' &&
                <ChatInputSection colorScheme={colorScheme} />
            }
        </span>
    </MainDiv>
}

Messages.propTypes = {
    auth: PropTypes.object.isRequired,
    chat: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    promotion: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    getMessages: PropTypes.func.isRequired,
    setIsMoreMessagesLoading: PropTypes.func.isRequired,
    loadMoreMessages: PropTypes.func.isRequired,
    colorScheme: PropTypes.object.isRequired,
    updateIsMessagesLoading: PropTypes.func.isRequired,
    readMessage: PropTypes.func.isRequired,
    addNewMessageInEnd: PropTypes.func.isRequired,
    updateMessageAction: PropTypes.func.isRequired,
    updateUsersLastMessage: PropTypes.func.isRequired,
    sendTextMessage: PropTypes.func.isRequired,
    openCopyToClipboardToastWithOffset: PropTypes.func.isRequired,
    updateUserList: PropTypes.func.isRequired,
    getAllPromotionOffers: PropTypes.func.isRequired,
    showBanner: PropTypes.func
}

const mapStateToProps = state => ({
    auth: state.auth,
    chat: state.chat,
    promotion: state.promotion
})

export default connect(
    mapStateToProps,
    {
        getMessages,
        setIsMoreMessagesLoading,
        loadMoreMessages,
        readMessage,
        addNewMessageInEnd,
        updateMessageAction,
        updateIsMessagesLoading,
        sendTextMessage,
        openCopyToClipboardToastWithOffset,
        updateUsersLastMessage,
        updateUserList,
        getAllPromotionOffers,
        showBanner
    }
)(withRouter(Messages))
