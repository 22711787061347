import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import _ from 'lodash'
import styled from 'styled-components'
import { logoutUser } from '../../actions/authActions'
import { toggleNotificationPopup, toggleSideBar, handleIsProgressiveWebAppPopupVisible, handleProgressiveWebAppPopupShowAgain, sendMobileInfoToServices } from './../../actions/utilityActions'
import { getNextMeetingTime } from '../../actions/chatActions'
import ChatBookingTimer from './ChatBookingTimer'
import GroupLiveNotificationBar from './GroupLiveNotificationBar'
import PromotionNotificationBar from './PromotionNotificationBar'
import OptInEmailBanner from './../opt-in-email/OptInEmailBanner'
import socket from '../../utils/socket'
import ResubscribeOfferNotificationBar from './ResubscribeOfferNotificationBar'
import { resetCurrentPage } from '../../actions/blogActions'
import { ROLE_LIVE_STREAM_MANAGER, SUBSCRIPTION_STATUS_ACTIVE, SUBSCRIPTION_STATUS_ACTIVE_CANCELLED, ROLE_ADMIN, ROLE_MODEL, ROLE_CONTENT_MANAGER, ROLE_SUB_ADMIN } from './../../utils/constant'
import { getCloudFrontAssetsUrl } from '../../utils/assets'
import { size } from '../../utils/common'

const NavLink = styled(Link)`
    font-size: 15px;
    color: ${props => props.color} !important;

    :hover {
        color: ${props => props.color};
    }

    @media (max-width: 991px){
        margin: ${props => props.centerenabled ? 'auto' : 'unset'}
    }
    @media (min-width: ${size.mobileS}) and (max-width: ${size.tablet}) {
        font-size: 20px;
        padding: 5px !important;
    }
`

const NavLinkForDropDown = styled(Link)`
    vertical-align: middle;
    display: inline-flex !important;
    width: 100%;
    font-size: 15px;
    color: ${props => props.color} !important;
    font-weight: ${props => props.fontWeight} !important;

    :hover {
        color: ${props => props.color};
    }
    
    @media (min-width: ${size.mobileS}) and (max-width: ${size.tablet}) {
        font-size: 20px;
        padding: 5px !important;
    }
`

const UnreadCountSpan = styled.span`
    width: 100%;
    font-size: 15px;
    font-weight: ${props => props.fontWeight} !important;

    :hover {
        color: ${props => props.color};
    }

    @media (min-width: ${size.mobileS}) and (max-width: ${size.tablet}) {
        font-size: 20px;
    }
`

const NavLinkA = styled.a`
    font-size: 15px;
    color: ${props => props.color};
    font-weight: ${props => props.fontWeight};

    :hover {
        color: ${props => props.color};
    }

    :focus {
        color: ${props => props.color};
        text-decoration: none;
    }

    @media (max-width: 991px){
        margin: ${props => props.centerenabled ? 'auto' : 'unset'}
    }

    @media (min-width: ${size.mobileS}) and (max-width: ${size.tablet}) {
        font-size: 20px;
        padding: 5px !important;
        margin: ${props => props.centerenabled ? 'auto' : 'unset'}
    }
`


const Icons = styled.div`
    width: 24px;
    height: 24px;
    background-color: ${props => props.color};
    mask: url(${props => props.image});
    -webkit-mask: url(${props => props.image}) no-repeat center / cover;
`

const Logout = styled.div`
    font-size: 15px;
    color: ${props => props.color};
    font-weight: ${props => props.fontWeight};
    @media (min-width: 320px) and (max-width: 768px) {
        font-size: 20px;
        padding: 5px !important;
    }
`

// const ExternalShopUrl = styled.a`
//     font-size: 15px;
//     color: ${props => props.color};
//     font-weight: ${props => props.fontWeight};

//     :hover {
//         color: ${props => props.color};
//     }
//     @media (min-width: ${size.mobileS}) and (max-width: ${size.tablet}) {
//         font-size: 20px;
//         padding: 5px !important;
//     }
// `

const NotificationCount = styled.div`
    color: ${props => props.content_font_color} !important;
    background: ${props => props.content_color} !important;
    font-size: 75%;
    height: 25px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
`

const SiteNameNavBarDiv = styled.div`
    @media (min-width: ${size.mobileS}) and (max-width: 363px) {
        width: 48%;
        display: flex;
    }

    @media (min-width: 364px) and (max-width: ${size.tablet}) {
        width: ${props => (props.isAuthenticated && (window.orientation !== undefined) && (!window.matchMedia('(display-mode: standalone)').matches)) ? '56%' : '64%'};
        display: flex;
    }
`

const StyledNav = styled.nav`
    background-color: ${props => props.themeColor} !important;
    padding: ${props => props.padding} !important;
    z-index: ${props => props.isShowBootstrapNavbar ? '1' : '4'} !important;
    justify-content: space-between;
    align-items: center;

    @media (min-width: ${size.mobileS}) and (max-width: ${size.tabletL}) {
        position: -webkit-sticky !important;
        position: sticky !important;
        top: 0 !important;
        z-index:  ${props => props.isShowBootstrapNavbar ? '1' : '1000'} !important;
        display:  ${props => props.hideNavbar ? 'none' : 'auto'} !important;
    }

`

const PurchasedNotificationDropDown = styled.div`
    @media (min-width: 1312px) {
        top: 34px !important;
    }

    top: 46px !important;

    @media (min-width: ${size.mobileS}) and (max-width: ${size.tabletL}) {
        left: auto !important;
        right: -5px !important;
        top: 43px !important;
    }
}
`

const PurchasedNotificationAndInfoDiv = styled.div`
    display: flex;
`

const InfoDiv = styled.div`
    align-content: center;
    display: flex;
    flex-wrap: wrap;
    color: ${props => props.color};
    cursor: pointer;
`

const NavbarName = styled.div`
    @media (min-width: 991px) {
        min-width: 120px;
        margin-right: 18px;
        word-break: break-word;
    }
`

const StyledStrong = styled.strong`
    @media (min-width: ${size.mobileS}) and (max-width: ${size.tablet}) {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`

const StyledLi = styled.li`
    @media (min-width: ${size.mobileS}) and (max-width: ${size.tablet}) {
        padding-right: 0px !important;
    }
`

class BootstrapNavbar extends Component {

    constructor(props) {
        super(props)
        this.state = { isOpen: false, chatStartTime: null }

        this.toggleCollapse = this.toggleCollapse.bind(this)
        this.onLogout = this.onLogout.bind(this)
        this.onNotificationBellClick = this.onNotificationBellClick.bind(this)
        this.openProgressiveWebAppPopup = this.openProgressiveWebAppPopup.bind(this)
    }

    onLogout(e) {
        e.preventDefault()
        this.props.resetCurrentPage()
        this.props.logoutUser(this.props.history)
        socket.emit('LOGOUT_USER')
        const pathName = this.props.history.location.pathname
        if (pathName.includes('/feed/') === true) {
            this.props.history.push('/')
        }
    }

    toggleCollapse() {
        this.setState({ isOpen: !this.state.isOpen })
    }

    onNotificationBellClick() {
        this.props.toggleNotificationPopup()
    }

    upcomingChatCountdownRenderer({ days, hours, minutes, seconds, completed }) {
        if (completed) {
            // Render a completed state
            return <></>
        } else {
            if (days > 0) {
                return days + ' days'
            }
            if (days === 0 && hours > 0) {
                return hours + ' hours, ' + minutes + ' mins'
            }
            if (days === 0 && hours === 0 && minutes > 0) {
                return minutes + ' mins'
            }
            if (days === 0 && hours === 0 && minutes === 0 && seconds > 0) {
                return seconds + ' seconds'
            }
        }
    }

    getUserRole() {
        let userRole = ''
        if (this.props.auth.isAuthenticated) {
            if (this.props.auth.user.isAdmin === true) {
                if (this.props.auth.user.role === 'content_manager') {
                    userRole = 'content_manager'
                } else if (this.props.auth.isModel === true) {
                    userRole = 'admin'
                } else if (this.props.auth.user.role === 'live_stream_manager') {
                    userRole = 'live_stream_manager'
                } else {
                    userRole = 'super_admin'
                }
            } else if (this.props.auth.user.payment.membership === true) {
                userRole = 'subscriber'
            } else {
                userRole = 'user'
            }
        } else {
            userRole = 'guest'
        }
        return userRole
    }

    getCustomMenuLinks(enable_center_navigation_menu_option, theme_font_color, navbar_font_weight) {
        return this.props.auth.customMenuLinks.map((item, i) => {
            const url = item.url
            return (
                <li className='nav-item' key={i}>
                    <NavLinkA centerenabled={enable_center_navigation_menu_option} href={url} className='nav-link' color={theme_font_color} fontWeight={navbar_font_weight} target={item.open_in_new_tab === true ? '_blank' : '_self'} rel="noreferrer">
                        {item.name}
                    </NavLinkA>
                </li>
            )
        })
    }

    notificationUnreadCounts(count) {
        return count > 99 ? '99+' : count
    }

    openProgressiveWebAppPopup(e) {
        document.querySelector('body').style.overflow = 'hidden'
        e.preventDefault()
        if (this.props.utility.isProgressiveWebAppPopupVisible === false) {
            this.props.handleIsProgressiveWebAppPopupVisible(true)
            this.props.handleProgressiveWebAppPopupShowAgain(false)
            const data = {
                is_running_from_pwa: window.matchMedia('(display-mode: standalone)').matches === true,
                user_id: this.props.auth.isAuthenticated === true ? this.props.auth.user._id : '',
                user_agent: window.navigator.userAgent,
                is_popup_opened: true,
                ccbill_subscription_status: this.props.auth.user.ccbillSubscriptionStatus
            }
            this.props.sendMobileInfoToServices(data)
        }
    }

    render() {
        let authLoginDiv
        let authSignupDiv
        let authAccountDiv
        const {
            model_name,
            theme_color,
            theme_font_color,
            exclusive_content_navbar_name,
            chat_navbar_name,
            settings_navbar_name,
            shop_navbar_name,
            user_details_navbar_name,
            tips_navbar_name,
            log_in_navbar_name,
            sign_up_navbar_name,
            log_out_navbar_name,
            transaction_reports_navbar_name,
            links_page_navbar_name,
            shop_menu,
            tips_menu,
            chat_menu,
            gallery_menu,
            links_menu,
            navbar_welcome_text,
            navbar_font_weight,
            navbar_padding,
            enable_internal_shop,
            chat_booking_enabled,
            chat_settings,
            enable_recently_purchased_for_subscribers,
            recently_purchased_navbar_name,
            enable_csv_download_for_content_managers,
            content_color,
            content_font_color,
            enable_purchase_notification,
            enable_socket_debug,
            my_account_navbar_name,
            enable_center_navigation_menu,
            promotion_settings,
            card_background_color,
            site_font_color,
            influencer_help_enabled,
            user_influencer_help_enabled,
            show_reporting_page_to_content_manager,
            hide_earnings,
            is_feed_comment_enabled,
            is_feed_like_enabled,
            is_crypto_payment_enabled,
            navbar,
            model_id
        } = this.props.auth.appSettings

        const { showBootstrapNavbar } = this.props.chat

        const { isAdmin, ccbillSubscriptionStatus, role, expiration_date } = this.props.auth.user
        const { isAuthenticated, customMenuLinks } = this.props.auth
        const { selectedUserId } = this.props.chat
        let enable_center_navigation_menu_option = enable_center_navigation_menu ? 1 : 0
        const myAccountUrl = '/profile/update'
        const notificationBarBackgroundColor = chat_settings.notification_bar_background_color
        const notificationBarTextColor = chat_settings.notification_bar_text_color
        let padding = (navbar_padding === undefined || navbar_padding === '') ? '0.5rem 1rem' : navbar_padding

        let showShop = false
        let showTips = false
        let showChat = false
        let showGallery = false
        let showSettings = true
        let showUsers = true
        let showTransactionReports = true
        let showLinksPage = false
        let showSideMenuIcon = false
        let showCartIcon = false
        let hidePurchased = false
        let isExpired = false
        const membership = _.get(this.props.auth, 'user.payment.membership', 'false')

        let ribbonPosition = _.get(promotion_settings, 'subscription_promotion_ribbon_position', 'above menu')
        if (isAdmin === true || [SUBSCRIPTION_STATUS_ACTIVE, SUBSCRIPTION_STATUS_ACTIVE_CANCELLED].includes(ccbillSubscriptionStatus)) {
            ribbonPosition = _.get(promotion_settings, 'locked_content_promotion_ribbon_position', 'above menu')
        }
        const pageListForPromotionBanner = ['/', '/admin/chat', '/tips', '/shop']
        const showPromotionBannerToAdmin = pageListForPromotionBanner.includes(window.location.pathname) || window.location.pathname.includes('/private-chat') === true
        const purchasedLink = membership === true ? '/purchased-contents' : '/membership'
        const isProfileURL = window.location.pathname.includes('profile')
        const currentUrl = window.location.pathname
        const showSignupButton = isAuthenticated === false && currentUrl === '/login' ? true : false
        const showLoginButton = isAuthenticated === false && currentUrl !== '/login' ? true : false

        const expDate = new Date(expiration_date)
        const currentDate = new Date()
        if ((currentDate > expDate) && ccbillSubscriptionStatus === '1') {
            isExpired = true
        }

        if (!isAuthenticated) {
            authLoginDiv = <li className='nav-item'>
                <NavLink centerenabled={enable_center_navigation_menu_option} className='nav-link' to='/login' color={theme_font_color} fontWeight={navbar_font_weight}>
                    {log_in_navbar_name}
                </NavLink>
            </li>

            authSignupDiv = <li className='nav-item'>
                <NavLink centerenabled={enable_center_navigation_menu_option} className='nav-link' to='/register' color={theme_font_color} fontWeight={navbar_font_weight}>
                    {sign_up_navbar_name}
                </NavLink>
            </li>

            authAccountDiv = null
        } else {
            authLoginDiv = <li className='nav-item' style={{ margin: enable_center_navigation_menu === true && window.innerWidth < 992 ? 'auto' : 'unset' }}>
                <Logout
                    color={theme_font_color}
                    fontWeight={navbar_font_weight}
                    onClick={this.onLogout}
                >
                    {log_out_navbar_name}
                </Logout>
            </li>
            authSignupDiv = null
            if (this.props.auth.user.avatarUrl) {
                authAccountDiv = <li className='nav-item'>
                    <NavLink centerenabled={enable_center_navigation_menu_option} className='nav-link d-md-block d-none' to={myAccountUrl} fontWeight={navbar_font_weight} color={theme_font_color}>
                        <img src={this.props.auth.user.avatarUrl} style={{ height: '34px', borderRadius: '17px', marginRight: '5px' }} alt='' /> {my_account_navbar_name}
                    </NavLink>
                    <NavLink centerenabled={enable_center_navigation_menu_option} className='nav-link d-md-none' to='/profile' fontWeight={navbar_font_weight} color={theme_font_color}>
                        <img src={this.props.auth.user.avatarUrl} style={{ height: '34px', borderRadius: '17px', marginRight: '5px' }} alt='' /> {my_account_navbar_name}
                    </NavLink>
                    {enable_socket_debug === true &&
                        <>
                            {this.props.socket.isSocketConnected === true ?
                                <div style={{ background: 'green', height: '10px', width: '10px', borderRadius: '50%' }}></div>
                                :
                                <div style={{ background: 'red', height: '10px', width: '10px', borderRadius: '50%' }}></div>
                            }
                        </>
                    }
                </li>
            } else {
                authAccountDiv = <li className='nav-item'>
                    <NavLink centerenabled={enable_center_navigation_menu_option} className='nav-link d-md-block d-none' to={myAccountUrl} fontWeight={navbar_font_weight} color={theme_font_color}>
                        <img src={getCloudFrontAssetsUrl('faces/avatar.png')} alt='User Avatar' style={{ height: '34px', borderRadius: '17px', marginRight: '5px' }} /> {my_account_navbar_name}
                    </NavLink>
                    <NavLink centerenabled={enable_center_navigation_menu_option} className='nav-link d-md-none' to='/profile' fontWeight={navbar_font_weight} color={theme_font_color}>
                        <img src={getCloudFrontAssetsUrl('faces/avatar.png')} alt='User Avatar' style={{ height: '34px', borderRadius: '17px', marginRight: '5px' }} /> {my_account_navbar_name}
                    </NavLink>
                    {enable_socket_debug === true &&
                        <>
                            {this.props.socket.isSocketConnected === true ?
                                <div style={{ background: 'green', height: '10px', width: '10px', borderRadius: '50%' }}></div>
                                :
                                <div style={{ background: 'red', height: '10px', width: '10px', borderRadius: '50%' }}></div>
                            }
                        </>
                    }
                </li>
            }
        }

        if (this.props.auth.isModel === true) {
            showTransactionReports = false
        }

        if (this.getUserRole() === 'content_manager') {
            showChat = true
            showTransactionReports = false
            showSettings = true

            if (hide_earnings) {
                hidePurchased = true
            }

            if (!show_reporting_page_to_content_manager || hide_earnings) {
                showUsers = false
            }
        } else if (this.getUserRole() === 'live_stream_manager') {
            showChat = true
            showSettings = false
            showTransactionReports = false
            showUsers = false
        } else {
            showShop = shop_menu[this.getUserRole()]
            showTips = tips_menu[this.getUserRole()]
            showChat = chat_menu[this.getUserRole()]
            showGallery = gallery_menu[this.getUserRole()]
            showLinksPage = links_menu[this.getUserRole()]
            showCartIcon = shop_menu[this.getUserRole()] && isAuthenticated
        }

        if (window.location.pathname.includes('/admin')) {
            showSideMenuIcon = true
        }

        const walletAmountText = this.props.auth.user.wallet_amount === 'NA' ? this.props.auth.user.wallet_amount : `$${parseFloat(this.props.auth.user.wallet_amount).toFixed(2)}`
        const avatarUrl = navbar.avatar_url ? navbar.avatar_url : this.props.chat.userProfileInfo[model_id] ? this.props.chat.userProfileInfo[model_id].profile : false
        const influencerNameColor = navbar.influencer_name_color ? navbar.influencer_name_color : theme_font_color

        const purchasedNotificationDiv = <div
            className='py-md-0 p-1 position-relative nav-link d-flex'
            id='navbarDropdown'
            role='button'
        >
            <div className='d-flex align-items-center' onClick={this.onNotificationBellClick}>
                <Icons image={getCloudFrontAssetsUrl('images/notifications.svg')} style={{ minWidth: '24px' }} color={theme_font_color}></Icons>
                {
                    (this.props.auth.user.totalUnreadCount > 0 || this.props.influencerHelp.unread_count > 0) &&
                    <UnreadCountSpan color={theme_font_color} fontWeight={navbar_font_weight} style={{ color: theme_font_color }}>
                        &nbsp;&nbsp;{this.props.auth.user.totalUnreadCount + ((this.props.influencerHelp.unread_count > 0) ? this.props.influencerHelp.unread_count : 0)}
                    </UnreadCountSpan>
                }
            </div>
            {this.props.utility.showNotificationPopup === true &&
                <PurchasedNotificationDropDown
                    className='dropdown-menu show'
                    style={{
                        background: card_background_color,
                        color: site_font_color,
                        minWidth: '11em',
                        maxWidth: '12em'
                    }}
                >
                    {!hidePurchased && <NavLinkForDropDown className='p-2 nav-link' to='/notification/purchased' fontWeight={navbar_font_weight} color={site_font_color}
                    >
                        Purchased
                        {
                            this.props.auth.user.purchasedNotificationUnreadCount > 0 &&
                            <NotificationCount className='ml-auto rounded-pill' content_color={content_color} content_font_color={content_font_color}>
                                {this.notificationUnreadCounts(this.props.auth.user.purchasedNotificationUnreadCount)}
                            </NotificationCount>
                        }
                    </NavLinkForDropDown>}
                    <NavLinkForDropDown className='p-2 nav-link' to='/notification/subscription' fontWeight={navbar_font_weight} color={site_font_color}
                    >
                        Subscription
                        {
                            this.props.auth.user.subscriptionNotificationUnreadCount > 0 &&
                            <NotificationCount className='ml-auto rounded-pill' content_color={content_color} content_font_color={content_font_color}>
                                {this.notificationUnreadCounts(this.props.auth.user.subscriptionNotificationUnreadCount)}
                            </NotificationCount>
                        }
                    </NavLinkForDropDown>
                    {is_feed_comment_enabled === true && <NavLinkForDropDown className='p-2 nav-link' to='/notification/comments' fontWeight={navbar_font_weight} color={site_font_color}
                    >
                        Comments
                        {
                            this.props.auth.user.feedCommentNotificationUnreadCount > 0 &&
                            <NotificationCount className='ml-auto rounded-pill' content_color={content_color} content_font_color={content_font_color}>
                                {this.notificationUnreadCounts(this.props.auth.user.feedCommentNotificationUnreadCount)}
                            </NotificationCount>
                        }
                    </NavLinkForDropDown>}
                    {is_feed_like_enabled === true &&
                        <NavLinkForDropDown className='p-2 nav-link' to='/notification/likes'
                            fontWeight={navbar_font_weight} color={site_font_color}>
                            Likes
                            {
                                this.props.auth.user.feedLikeNotificationUnreadCount > 0 &&
                                <NotificationCount className='ml-auto rounded-pill' content_color={content_color} content_font_color={content_font_color}>
                                    {this.notificationUnreadCounts(this.props.auth.user.feedLikeNotificationUnreadCount)}
                                </NotificationCount>
                            }
                        </NavLinkForDropDown>}
                    {influencer_help_enabled === true &&
                        <NavLinkForDropDown className='p-2 nav-link' to='/notification/notification' fontWeight={navbar_font_weight} color={site_font_color}>
                            Notification
                            {
                                this.props.influencerHelp.unread_count > 0 &&
                                <NotificationCount className='ml-auto rounded-pill' content_color={content_color} content_font_color={content_font_color}>
                                    {this.notificationUnreadCounts(this.props.influencerHelp.unread_count)}
                                </NotificationCount>
                            }
                        </NavLinkForDropDown>
                    }
                </PurchasedNotificationDropDown>
            }
        </div>

        const walletAmountSection = <StyledLi className='nav-item'>
            <NavLink centerenabled={enable_center_navigation_menu_option} className='nav-link' to='/profile/wallet' fontWeight={navbar_font_weight}>
                <i className='fas fa-wallet' style={{ color: theme_font_color }}></i>
                <span style={{ color: theme_font_color }}>&nbsp;&nbsp;{walletAmountText}</span>
            </NavLink>
        </StyledLi>

        if (this.props.auth.isSupport) {
            return (
                <nav className='navbar navbar-expand-lg' style={{ backgroundColor: theme_color, padding: padding, zIndex: 3 }}>
                    {!this.props.auth.isContentManager && showSideMenuIcon ? <button className='navbar-toggler d-md-none' type='button' data-toggle='collapse' data-target='#sidebarMenu' aria-controls='sidebarMenu' aria-expanded='true' aria-label='Toggle navigation' onClick={this.props.toggleSideBar}>
                        <i className='fas fa-cogs' style={{ color: theme_font_color }}></i>
                    </button> : null}
                    {(navbar.show_avatar_image && avatarUrl) && <img src={avatarUrl} style={{ height: '34px', borderRadius: '17px', marginRight: '5px' }} alt='' />}
                    {navbar.show_influencer_name && <strong style={{ color: influencerNameColor, fontSize: '18px' }}>
                        {(navbar_welcome_text === undefined || navbar_welcome_text === '') ? `WELCOME TO ${model_name.toUpperCase()}` : `${navbar_welcome_text}`}
                    </strong>}
                    <button className='navbar-toggler collapsed' type='button' data-toggle='collapse' onClick={this.toggleCollapse}>
                        <i className='fas fa-bars' style={{ color: theme_font_color }}></i>
                    </button>

                    <div className={this.state.isOpen ? 'collapse navbar-collapse show' : 'collapse navbar-collapse'} id='navbarsExampleDefault'>
                        <ul className='navbar-nav mr-auto'></ul>
                        <ul className='navbar-nav'>
                            <li className='nav-item'>
                                <NavLink centerenabled={enable_center_navigation_menu_option} className='nav-link' to='/admin/transactions' color={theme_font_color} fontWeight={navbar_font_weight}>
                                    {transaction_reports_navbar_name}
                                </NavLink>
                            </li>
                            {authAccountDiv}
                            {authLoginDiv}
                            {authSignupDiv}
                        </ul>
                    </div>
                </nav>
            )
        }

        if (this.props.auth.user.isAdmin) {
            const pathName = window.location.pathname
            let hideNavbar = false
            if (pathName.includes('/admin/chat/')) {
                hideNavbar = true
            }
            return (<>
                {showPromotionBannerToAdmin && ribbonPosition === 'above menu' ?
                    <PromotionNotificationBar /> : <></>
                }
                <StyledNav
                    className='navbar navbar-expand-lg'
                    themeColor={theme_color}
                    padding={padding}
                    hideNavbar={hideNavbar}
                    isShowBootstrapNavbar={showBootstrapNavbar}
                >
                    <div className='d-flex align-items-center'>
                        {(navbar.show_avatar_image && avatarUrl) && <img src={avatarUrl} style={{ height: '34px', borderRadius: '17px', marginRight: '5px' }} alt='' />}
                        {navbar.show_influencer_name && <NavbarName style={{ color: influencerNameColor, fontSize: '18px', fontWeight: '600' }}>
                            {(navbar_welcome_text === undefined || navbar_welcome_text === '') ? `WELCOME TO ${model_name.toUpperCase()}` : `${navbar_welcome_text}`}
                        </NavbarName>}
                    </div>
                    <PurchasedNotificationAndInfoDiv>
                        {(isAuthenticated && (window.orientation !== undefined) && (!window.matchMedia('(display-mode: standalone)').matches)) && <InfoDiv className='d-lg-none' onClick={this.openProgressiveWebAppPopup} color={theme_font_color}>
                            <Icons image={getCloudFrontAssetsUrl('images/ios-share.svg')} alt='share' color={theme_font_color} />
                        </InfoDiv>}
                        {isAuthenticated && role !== ROLE_LIVE_STREAM_MANAGER && <div className='d-lg-none'>{purchasedNotificationDiv}</div>}
                    </PurchasedNotificationAndInfoDiv>
                    <div className='collapse navbar-collapse' id='navbarsExampleDefault'>
                        <ul className='navbar-nav mr-auto'></ul>
                        <ul className='navbar-nav'>
                            <li className='nav-item'>
                                <NavLink centerenabled={enable_center_navigation_menu_option} className='nav-link' to='/' color={theme_font_color} fontWeight={navbar_font_weight}>
                                    {exclusive_content_navbar_name}
                                </NavLink>
                            </li>

                            {this.props.auth.isContentManager && enable_csv_download_for_content_managers === true ? <li className='nav-item'>
                                <NavLink centerenabled={enable_center_navigation_menu_option} className='nav-link' to='/admin/settings/export-users' color={theme_font_color} fontWeight={navbar_font_weight}>
                                    EXPORT USERS
                                </NavLink>
                            </li> : null}
                            {showChat ? <li className='nav-item'>
                                <NavLink centerenabled={enable_center_navigation_menu_option} className='nav-link' to={selectedUserId ? `/admin/chat/${selectedUserId}` : '/admin/chat'} color={theme_font_color} fontWeight={navbar_font_weight}>
                                    {chat_navbar_name}
                                </NavLink>
                            </li> : null}
                            {showTips ? <li className='nav-item'>
                                <NavLink centerenabled={enable_center_navigation_menu_option} className='nav-link' to='/tips' color={theme_font_color} fontWeight={navbar_font_weight}>
                                    {tips_navbar_name}
                                </NavLink>
                            </li> : null}
                            {showSettings ? <li className='nav-item'>
                                <NavLink centerenabled={enable_center_navigation_menu_option} className='nav-link' to='/admin/settings' color={theme_font_color} fontWeight={navbar_font_weight}>
                                    {settings_navbar_name}
                                </NavLink>
                            </li> : null}
                            {showShop ?
                                <>
                                    {
                                        enable_internal_shop &&
                                        <li className='nav-item'>
                                            <NavLink centerenabled={enable_center_navigation_menu_option} className='nav-link' to='/shop' color={theme_font_color} fontWeight={navbar_font_weight}>
                                                {shop_navbar_name}
                                            </NavLink>
                                        </li>
                                    }
                                </>
                                : null
                            }
                            {customMenuLinks.length > 0 &&
                                this.getCustomMenuLinks(enable_center_navigation_menu_option, theme_font_color, navbar_font_weight)
                            }
                            {showGallery ?
                                <li className='nav-item d-none'>
                                    <NavLink centerenabled={enable_center_navigation_menu_option} className='nav-link' to='/gallery' color={theme_font_color} fontWeight={navbar_font_weight}>
                                        GALLERY
                                    </NavLink>
                                </li> : null
                            }
                            {showUsers ? <li className='nav-item'>
                                <NavLink centerenabled={enable_center_navigation_menu_option} className='nav-link' to='/admin/subscribers' color={theme_font_color} fontWeight={navbar_font_weight}>
                                    {user_details_navbar_name}
                                </NavLink>
                            </li> : null}
                            {showTransactionReports ? <li className='nav-item'>
                                <NavLink centerenabled={enable_center_navigation_menu_option} className='nav-link' to='/admin/transactions' color={theme_font_color} fontWeight={navbar_font_weight}>
                                    {transaction_reports_navbar_name}
                                </NavLink>
                            </li> : null}
                            {showLinksPage ? <li className='nav-item'>
                                <NavLink centerenabled={enable_center_navigation_menu_option} className='nav-link' to='/links' color={theme_font_color} fontWeight={navbar_font_weight}>
                                    {links_page_navbar_name}
                                </NavLink>
                            </li> : null}
                            {(isAuthenticated && (role === ROLE_ADMIN || role === ROLE_MODEL || role === ROLE_CONTENT_MANAGER || role === ROLE_SUB_ADMIN)) ? <li className='nav-item'>
                                <NavLink centerenabled={enable_center_navigation_menu_option} className='nav-link' to='/admin/content-manager' fontWeight={navbar_font_weight}>
                                    <i className='fas fa-photo-video' style={{ color: theme_font_color, fontSize: '19px' }}></i>
                                </NavLink>
                            </li> : null}
                            {(isAuthenticated && role !== ROLE_LIVE_STREAM_MANAGER) ? <li className='nav-item'>
                                <NavLink centerenabled={enable_center_navigation_menu_option} className='nav-link p-md-0' to={selectedUserId ? `/admin/chat/${selectedUserId}` : '/admin/chat'} color={theme_font_color} fontWeight={navbar_font_weight}>
                                    <div className='d-flex justify-content-center align-items-center'>
                                        <Icons image={getCloudFrontAssetsUrl('images/email.svg')} color={theme_font_color}></Icons>
                                        {
                                            this.props.auth.counts.userUnreadMessage > 0 &&
                                            <span style={{ color: theme_font_color }}>
                                                &nbsp;&nbsp;{this.props.auth.counts.userUnreadMessage}
                                            </span>
                                        }
                                    </div>
                                </NavLink>
                            </li> : null}
                            {(isAuthenticated && role !== ROLE_LIVE_STREAM_MANAGER) ? <li className='nav-item'>
                                <NavLink centerenabled={enable_center_navigation_menu_option} className='nav-link p-md-0' to='/admin/chat/tips-list' color={theme_font_color} fontWeight={navbar_font_weight}>
                                    <div className='d-flex justify-content-center align-items-center'>
                                        <Icons image={getCloudFrontAssetsUrl('images/dollar.svg')} color={theme_font_color}></Icons>
                                        {
                                            this.props.auth.counts.modelUnreadTips > 0 &&
                                            <span style={{ color: theme_font_color }}>
                                                &nbsp;&nbsp;{this.props.auth.counts.modelUnreadTips}
                                            </span>
                                        }
                                    </div>
                                </NavLink>
                            </li> : null}
                            {showCartIcon &&
                                (this.props.auth.counts.totalQuantity > 0) && enable_internal_shop ?
                                <li className='nav-item'>
                                    <NavLink centerenabled={enable_center_navigation_menu_option} className='nav-link' to='/shop/view-cart' fontWeight={navbar_font_weight}>
                                        <i className='fas fa-shopping-cart' style={{ color: theme_font_color }}></i>
                                        <span style={{ color: theme_font_color }}>&nbsp;&nbsp;{this.props.auth.counts.totalQuantity}</span>
                                    </NavLink>
                                </li> : null}
                            {(enable_purchase_notification && role !== ROLE_LIVE_STREAM_MANAGER) &&
                                <li className={enable_center_navigation_menu ? 'nav-item dropdown d-flex px-1' : 'nav-item dropdown d-flex'}>
                                    {purchasedNotificationDiv}
                                </li>
                            }
                            {(influencer_help_enabled && role !== ROLE_LIVE_STREAM_MANAGER) &&
                                <li className='nav-item'>
                                    <NavLink centerenabled={enable_center_navigation_menu_option} className={enable_center_navigation_menu ? 'nav-link p-md-0 mx-auto' : 'nav-link p-md-0'} to='/help' color={theme_font_color} fontWeight={navbar_font_weight}>
                                        <Icons
                                            image={getCloudFrontAssetsUrl('images/question-solid.svg')}
                                            color={theme_font_color}
                                            onClick={this.onNotificationBellClick}
                                            style={{ maxHeight: '20px', margin: 'auto' }}
                                        >
                                        </Icons>
                                    </NavLink>
                                </li>
                            }
                            {authAccountDiv}
                            {authLoginDiv}
                            {authSignupDiv}
                        </ul>
                    </div>
                </StyledNav>
                {
                    isAuthenticated && chat_booking_enabled && this.props.chatBookingTimer.numberOfLiveChatUser !== 1 &&
                    <div className='text-center' onClick={() => { return this.props.history.push('/admin/settings/set-chat-availability/booking-list') }} style={{ width: '100%', background: notificationBarBackgroundColor, color: notificationBarTextColor, cursor: 'pointer' }}>
                        <ChatBookingTimer />
                    </div>
                }
                {
                    isAuthenticated && chat_booking_enabled && this.props.chatBookingTimer.numberOfLiveChatUser === 1 &&
                    <div className='text-center' onClick={() => { return this.props.history.push(`/private-chat/${this.props.chatBookingTimer.liveChatUserId}`) }} style={{ width: '100%', background: notificationBarBackgroundColor, color: notificationBarTextColor, cursor: 'pointer' }}>
                        <ChatBookingTimer />
                    </div>
                }
                {
                    isAuthenticated && [SUBSCRIPTION_STATUS_ACTIVE, SUBSCRIPTION_STATUS_ACTIVE_CANCELLED].includes(ccbillSubscriptionStatus) &&
                    <div className='text-center' style={{ width: '100%', background: content_font_color, color: content_color }}>
                        <GroupLiveNotificationBar />
                    </div>
                }
                {
                    this.props.auth.user.isAdmin && this.props.auth.user.role !== 'model' &&
                    <div className='text-center' style={{ width: '100%', background: content_font_color, color: content_color }}>
                        <GroupLiveNotificationBar />
                    </div>
                }
                {
                    showPromotionBannerToAdmin && ribbonPosition === 'above hero' ?
                        <PromotionNotificationBar /> : <></>
                }
            </>
            )
        } else {
            const pathName = window.location.pathname
            let hideNavbar = false
            if (pathName.includes('/private-chat')) {
                hideNavbar = true
            }
            return (
                <>
                    {this.props.chat.isShowBanner === true && ribbonPosition === 'above menu' ?
                        <PromotionNotificationBar /> : <></>
                    }
                    {ccbillSubscriptionStatus !== SUBSCRIPTION_STATUS_ACTIVE &&
                        <ResubscribeOfferNotificationBar />
                    }
                    <StyledNav
                        className='navbar navbar-expand-lg'
                        themeColor={theme_color}
                        padding={padding}
                        hideNavbar={hideNavbar}
                    >
                        <SiteNameNavBarDiv isAuthenticated={isAuthenticated}>
                            {(navbar.show_avatar_image && avatarUrl) && <img src={avatarUrl} style={{ height: '34px', borderRadius: '17px', marginRight: '5px' }} alt='' />}
                            {navbar.show_influencer_name && <StyledStrong style={{ color: influencerNameColor, fontSize: '18px', paddingTop: '0.359rem', paddingBottom: '0.359rem' }}>
                                {(navbar_welcome_text === undefined || navbar_welcome_text === '') ? `WELCOME TO ${model_name.toUpperCase()}` : `${navbar_welcome_text}`}
                            </StyledStrong>}
                        </SiteNameNavBarDiv>
                        <PurchasedNotificationAndInfoDiv>
                            {(isAuthenticated && (window.orientation !== undefined) && (!window.matchMedia('(display-mode: standalone)').matches)) &&
                                <InfoDiv className='d-lg-none' onClick={this.openProgressiveWebAppPopup} color={theme_font_color}>
                                    <Icons image={getCloudFrontAssetsUrl('images/ios-share.svg')} alt='share' color={theme_font_color} />
                                </InfoDiv>
                            }
                            {showLoginButton === true &&
                                <NavLink className='nav-link navbar-toggler' to='/login' fontWeight={navbar_font_weight} color={theme_font_color}>
                                    {log_in_navbar_name}
                                </NavLink>
                            }
                            {showSignupButton === true &&
                                <NavLink className='nav-link navbar-toggler' to='/register' color={theme_font_color} fontWeight={navbar_font_weight}>
                                    {sign_up_navbar_name}
                                </NavLink>
                            }
                            {isAuthenticated && is_crypto_payment_enabled &&
                                <div className='d-lg-none'>
                                    {walletAmountSection}
                                </div>
                            }
                        </PurchasedNotificationAndInfoDiv>
                        <div className='collapse navbar-collapse' id='navbarsExampleDefault'>
                            <ul className='navbar-nav mr-auto'></ul>
                            <ul className='navbar-nav'>
                                <li className='nav-item'>
                                    <NavLink centerenabled={enable_center_navigation_menu_option} className='nav-link' to='/' color={theme_font_color} fontWeight={navbar_font_weight}>
                                        {exclusive_content_navbar_name}
                                    </NavLink>
                                </li>
                                {enable_recently_purchased_for_subscribers && isAuthenticated ? <li className='nav-item'>
                                    <NavLink centerenabled={enable_center_navigation_menu_option} className='nav-link' to={purchasedLink} color={theme_font_color} fontWeight={navbar_font_weight}>
                                        {recently_purchased_navbar_name}
                                    </NavLink>
                                </li> : null
                                }
                                {showChat ? <li className='nav-item'>
                                    <NavLink centerenabled={enable_center_navigation_menu_option} className='nav-link' to={['1', '2'].includes(ccbillSubscriptionStatus) && !isExpired ? '/private-chat' : '/chat'} color={theme_font_color} fontWeight={navbar_font_weight}>
                                        {chat_navbar_name}
                                    </NavLink>
                                </li> : null}
                                {showShop ?
                                    <>
                                        {
                                            enable_internal_shop &&
                                            <li className='nav-item'>
                                                <NavLink centerenabled={enable_center_navigation_menu_option} className='nav-link' to='/shop' color={theme_font_color} fontWeight={navbar_font_weight}>
                                                    {shop_navbar_name}
                                                </NavLink>
                                            </li>
                                        }
                                    </> : null
                                }
                                {customMenuLinks.length > 0 &&
                                    this.getCustomMenuLinks(enable_center_navigation_menu_option, theme_font_color, navbar_font_weight)
                                }
                                {showTips ? <li className='nav-item'>
                                    <NavLink centerenabled={enable_center_navigation_menu_option} className='nav-link' to='/tips' color={theme_font_color} fontWeight={navbar_font_weight}>
                                        {tips_navbar_name}
                                    </NavLink>
                                </li> : null}
                                {showGallery ?
                                    <li className='nav-item d-none'>
                                        <NavLink centerenabled={enable_center_navigation_menu_option} className='nav-link' to='/gallery' color={theme_font_color} fontWeight={navbar_font_weight}>
                                            GALLERY
                                        </NavLink>
                                    </li> : null
                                }
                                {showLinksPage ? <li className='nav-item'>
                                    <NavLink centerenabled={enable_center_navigation_menu_option} className='nav-link' to='/links' color={theme_font_color} fontWeight={navbar_font_weight}>
                                        {links_page_navbar_name}
                                    </NavLink>
                                </li> : null}
                                {(isAuthenticated) && (this.props.auth.counts.userUnreadMessage > 0) ? <li className='nav-item'>
                                    <NavLink centerenabled={enable_center_navigation_menu_option} className='nav-link p-md-0' to={['1', '2'].includes(ccbillSubscriptionStatus) && !isExpired ? '/private-chat' : '/chat'} color={theme_font_color} fontWeight={navbar_font_weight}>
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <Icons image={getCloudFrontAssetsUrl('images/email.svg')} color={theme_font_color}></Icons>
                                            {
                                                this.props.auth.counts.userUnreadMessage > 0 &&
                                                <span style={{ color: theme_font_color }}>&nbsp;&nbsp;{this.props.auth.counts.userUnreadMessage}</span>
                                            }
                                        </div>
                                    </NavLink>
                                </li> : null}
                                {(isAuthenticated && user_influencer_help_enabled) ?
                                    <li className='nav-item'>
                                        <NavLink centerenabled={enable_center_navigation_menu_option} className='nav-link p-md-0' to='/user-notification/notification' color={theme_font_color} fontWeight={navbar_font_weight}>
                                            <div className='d-flex justify-content-center align-items-center'>
                                                <Icons image={getCloudFrontAssetsUrl('images/notifications.svg')} color={theme_font_color}></Icons>
                                                {
                                                    this.props.userInfluencerHelp.unread_count > 0 &&
                                                    <span style={{ color: theme_font_color }}>&nbsp;&nbsp;{this.props.userInfluencerHelp.unread_count}</span>
                                                }
                                            </div>
                                        </NavLink>
                                    </li>
                                    :
                                    null
                                }
                                {(isAuthenticated && user_influencer_help_enabled) &&
                                    <li className='nav-item'>
                                        <NavLink centerenabled={enable_center_navigation_menu_option} className={enable_center_navigation_menu ? 'nav-link p-md-0 mx-auto' : 'nav-link p-md-0'} to='/user-help' color={theme_font_color} fontWeight={navbar_font_weight}>
                                            <Icons
                                                image={getCloudFrontAssetsUrl('images/question-solid.svg')}
                                                color={theme_font_color}
                                                style={{ maxHeight: '20px', margin: 'auto' }}
                                            >
                                            </Icons>
                                        </NavLink>
                                    </li>
                                }
                                {showCartIcon &&
                                    (this.props.auth.counts.totalQuantity > 0) && enable_internal_shop ?
                                    <li className='nav-item'>
                                        <NavLink centerenabled={enable_center_navigation_menu_option} className='nav-link' to='/shop/view-cart' fontWeight={navbar_font_weight}>
                                            <i className='fas fa-shopping-cart' style={{ color: theme_font_color }}></i>
                                            <span style={{ color: theme_font_color }}>&nbsp;{this.props.auth.counts.totalQuantity}</span>
                                        </NavLink>
                                    </li> : null}
                                {isAuthenticated && is_crypto_payment_enabled &&
                                    <>
                                        {walletAmountSection}
                                    </>
                                }
                                {authAccountDiv}
                                {authLoginDiv}
                                {authSignupDiv}
                            </ul>
                        </div>
                    </StyledNav>
                    {
                        isAuthenticated && chat_booking_enabled && this.props.chatBookingTimer.numberOfLiveChatUser === 0 &&
                        <div className='text-center'
                            onClick={() => { return this.props.history.push('/book-slot/booking-list') }}
                            style={{ width: '100%', background: notificationBarBackgroundColor, color: notificationBarTextColor, cursor: 'pointer' }}
                        >
                            <ChatBookingTimer />
                        </div>
                    }
                    {
                        isAuthenticated && chat_booking_enabled && this.props.chatBookingTimer.numberOfLiveChatUser > 0 &&
                        <div className='text-center'
                            onClick={() => { return this.props.history.push('/chat') }} style={{ width: '100%', background: notificationBarBackgroundColor, color: notificationBarTextColor, cursor: 'pointer' }}>
                            <ChatBookingTimer />
                        </div>
                    }
                    {
                        isAuthenticated && [SUBSCRIPTION_STATUS_ACTIVE, SUBSCRIPTION_STATUS_ACTIVE_CANCELLED].includes(ccbillSubscriptionStatus) &&
                        <div className='text-center' style={{ width: '100%', background: content_font_color, color: content_color }}>
                            <GroupLiveNotificationBar />
                        </div>
                    }
                    {
                        (isAuthenticated && this.props.auth.user.shouldPresentEmailOptIn === true && this.props.auth.user.emailOptInStatus === 'link_sent') || (this.props.auth.user.emailLog !== null) ?
                            <div className='text-center' style={{ width: '100%', background: content_font_color, color: content_color }}>
                                <OptInEmailBanner />
                            </div> : null
                    }
                    {
                        this.props.auth.user.isAdmin && this.props.auth.user.role !== 'model' &&
                        <div className='text-center' style={{ width: '100%', background: content_font_color, color: content_color }}>
                            <GroupLiveNotificationBar />
                        </div>
                    }
                    {
                        this.props.chat.isShowBanner === true && ribbonPosition === 'above hero' &&
                        <PromotionNotificationBar />
                    }
                    {
                        (window.location.pathname === '/login' || window.location.pathname === '/register' || window.location.pathname === '/forgot-password' || isProfileURL === true) && ribbonPosition === 'below hero' &&
                        <PromotionNotificationBar />
                    }
                </>
            )
        }
    }
}

BootstrapNavbar.propTypes = {
    auth: PropTypes.object.isRequired,
    chat: PropTypes.object.isRequired,
    chatBookingTimer: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    classes: PropTypes.object,
    logoutUser: PropTypes.func.isRequired,
    toggleSideBar: PropTypes.func.isRequired,
    getNextMeetingTime: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    socket: PropTypes.object.isRequired,
    promotion: PropTypes.object.isRequired,
    resetCurrentPage: PropTypes.func.isRequired,
    toggleNotificationPopup: PropTypes.func.isRequired,
    utility: PropTypes.object.isRequired,
    userInfluencerHelp: PropTypes.object,
    influencerHelp: PropTypes.object,
    handleIsProgressiveWebAppPopupVisible: PropTypes.func.isRequired,
    handleProgressiveWebAppPopupShowAgain: PropTypes.func.isRequired,
    sendMobileInfoToServices: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    chat: state.chat,
    errors: state.errors,
    chatBookingTimer: state.chatBookingTimer,
    socket: state.socket,
    promotion: state.promotion,
    utility: state.utility,
    userInfluencerHelp: state.userInfluencerHelp,
    influencerHelp: state.influencerHelp
})

export default connect(
    mapStateToProps,
    { logoutUser, toggleSideBar, getNextMeetingTime, resetCurrentPage, toggleNotificationPopup, handleIsProgressiveWebAppPopupVisible, sendMobileInfoToServices, handleProgressiveWebAppPopupShowAgain }
)(withRouter(BootstrapNavbar))
