import PropTypes from 'prop-types'
import React, { useRef, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Button from '../Layouts/Button'
import _ from 'lodash'
import { googleAnalyticsTrackEvent } from '../../utils/GoogleAnalyticsEvent'
import { chatPurchasePayment } from '../../actions/hybridPaymentAction'
import { purchaseFromWallet, updateWalletAmount } from '../../actions/cryptoPaymentActions'
import AddFundForm from '../crypto/AddFundForm'
import { setSweetAlert, setConfirmSweetAlert, setAlertLoader, removeConfirmSweetAlert } from '../../actions/sweetAlertActions'
import classNames from 'classnames'
import ConfirmSweetAlert from '../Layouts/ConfirmSweetAlert'

const UnlockMessage = (props) => {
    const { userList, selectedUserId, promotionDetails } = props.chat
    const [showAlert, setShowAlert] = useState(false)
    const [productName, setProductName] = useState('unlock chat')
    const [productSKU, setProductSKU] = useState('chat')
    const [title, setTitle] = useState('chat')
    const { message, auth, promotion, buttonBackgroundColor, buttonFontColor, isPreviewAvailable, ccbill } = props
    const { amount, type, _id, massMessageType, isMassMessage } = message
    const { lockedContentPromotion } = promotion
    const { enable_promotion, content_color, content_font_color, promotion_settings } = auth.appSettings
    const { isLoading } = ccbill
    const amountToPay = useRef(amount)
    const [unlockData, setUnlockData] = useState({})
    const [showAddFundPopup, setShowAddFundPopup] = useState(false)
    const [remainAmount, setRemainAmount] = useState(0)
    const isMassMessagePromotionActive = enable_promotion && lockedContentPromotion && ['LOCKED_CONTENT', 'EXCLUSIVE_CONTENT_AND_MASS_MESSAGE', 'MASS_MESSAGE'].includes(lockedContentPromotion.type) ? true : false

    const getPromotionPercentage = () => {
        let promotionPercentage = 0
        if (isMassMessagePromotionActive) {
            promotionPercentage = lockedContentPromotion.discount_percentage
        }
        return promotionPercentage
    }

    const getAmount = (amount) => {
        if (!message.isMassMessage) {
            return <>Unlock this {type} for ${amount}</>
        }
        let isModelFromOtherSite = false
        const modelInfo = userList.find(user => user._id === selectedUserId)
        if (modelInfo && modelInfo.domain !== window.location.hostname) {
            isModelFromOtherSite = true
        }
        const promotionPercentage = isModelFromOtherSite ? (promotionDetails[selectedUserId] ? promotionDetails[selectedUserId].promotionPercentage : 0) : getPromotionPercentage()
        if (promotionPercentage === 0) {
            return <>Unlock this {type} for ${amount}</>
        }

        const amountWithPromotionApplied = Math.ceil(amount * (100 - promotionPercentage) / 100)
        amountToPay.current = amountWithPromotionApplied
        return <>
            Unlock this {type} for&nbsp;
            <span style={{ textDecoration: 'line-through', textDecorationColor: promotion_settings.price_strike_through_color, textDecorationThickness: '2px' }}>
                ${amount}
            </span>
            &nbsp;${amountWithPromotionApplied}
        </>
    }

    const promotionAmount = () => {
        if (isMassMessage && isMassMessagePromotionActive === false) {
            return amount
        }

        const promotionPercentage = getPromotionPercentage()
        if (promotionPercentage === 0) {
            return amount
        }

        const amountWithPromotionApplied = Math.ceil(amount * (100 - promotionPercentage) / 100)
        amountToPay.current = amountWithPromotionApplied

        return amountWithPromotionApplied
    }

    const unlockUsingCrypto = async (messageData) => {
        let userWalletAmount = props.auth.user.wallet_amount
        let showDefaultAlert = true
        if (_.isNumber(messageData) === true) {
            userWalletAmount = messageData
            messageData = unlockData
            showDefaultAlert = false
        }
        const messageAmount = Number(parseFloat(messageData.amount).toFixed(2))
        const data = {
            payment_for: 'chat',
            content_id: messageData.messageId,
            userId: props.auth.user._id
        }
        if (_.get(messageData, 'promotion_id', false) !== false) {
            data.promotion_id = messageData.promotion_id
            data.is_promotion_applied = messageData.is_promotion_applied
        }
        if (props.auth.user.isAdmin === false) {
            const userDomain = userList.filter(obj => obj._id === selectedUserId)
            data.domain = userDomain[0].domain
            data.email = props.auth.user.email
        }
        if (userWalletAmount >= messageAmount) {
            const res = await props.purchaseFromWallet(data)
            if (res.success === 1) {
                props.updateWalletAmount(res.data.wallet_balance)
                setUnlockData({})
                if (showDefaultAlert || res.data.wallet_balance === 0) {
                    props.setSweetAlert({ description: res.data.message })
                } else {
                    props.setSweetAlert({ description: `The payment of $${res.data.transaction_amount} was successful. Your content was unlocked. The remainder of your crypto deposit is stored in your wallet and can be used for future transactions.` })
                }
            } else {
                const message = _.get(res, 'message', 'Payment failed.')
                props.setSweetAlert({ description: message })
            }
            return
        } else {
            if (userWalletAmount === 0.00) {
                setRemainAmount(0)
                setShowAddFundPopup(true)
            } else {
                const remainAmount = Math.ceil(messageAmount - userWalletAmount)
                setRemainAmount(remainAmount)
                setShowAddFundPopup(true)
            }
        }
    }

    const unlockContent = async () => {
        setTitle(message.message)
        const messageType = type
        const isMassMessage = message.isMassMessage
        const amount2 = amountToPay.current

        setProductSKU(messageType)
        if (messageType === 'photo') {
            setProductSKU('image')
        }

        setProductName('unlock chat')
        if (isMassMessage) {
            setProductName('unlock mass')
        }
        const massMessageType2 = massMessageType || ''
        const massMessageTypes = ['WELCOME', 'STAGGER', 'DELAYED', 'RESENT', 'PREVIOUS']
        if (isMassMessage === true && massMessageTypes.includes(massMessageType2)) {
            switch (massMessageType2) {
                case 'WELCOME':
                    setProductName('unlock welcome message')
                    break
                case 'STAGGER':
                    setProductName('unlock mass stagger')
                    break
                case 'DELAYED':
                    setProductName('unlock mass delayed')
                    break
                case 'RESENT':
                    setProductName('unlock mass resend')
                    break
                case 'PREVIOUS':
                    setProductName('unlock mass previous')
                    break
                default:
                    setProductName('unlock mass')
                    break
            }
        }
        // Set google analytics add_to_cart event for unlock mass or unlock chat
        const userDomain = userList.filter(obj => obj._id === selectedUserId)[0].domain
        if (userDomain === window.location.hostname) {
            googleAnalyticsTrackEvent('add_to_cart', '', amount2, productSKU, productName, title)
        }

        let confirmationMessage = `Please Confirm Your Purchase of $${amount2}.`
        if (props.auth.user.default_payment_method === 'crypto_currency') {
            confirmationMessage += `You currently have $${props.auth.user.wallet_amount} in your wallet. Amount will be debited from your wallet balance.`
        }
        const isCryptoPayment = auth.user.default_payment_method === 'crypto_currency'
        const hasSufficientFunds = auth.user.wallet_amount >= parseFloat(amount2)
        if (isCryptoPayment && !hasSufficientFunds) {
            unlockContentUsingPaymentMethod()
        } else {
            setShowAlert(true)
            props.setConfirmSweetAlert({ description: confirmationMessage })
        }
    }

    const unlockContentUsingPaymentMethod = async () => {
        let isPromotionApplied = message.isMassMessage && isMassMessagePromotionActive
        const id = _id
        const amount2 = amountToPay.current
        const { enable_promotion } = props.auth.appSettings
        const { lockedContentPromotion } = props.promotion
        let promotionId = false
        if (enable_promotion && lockedContentPromotion !== false && isPromotionApplied === true) {
            promotionId = _.get(lockedContentPromotion, '_id', false)
        }

        let isModelFromOtherSite = false
        const modelInfo = userList.find(user => user._id === selectedUserId)
        if (modelInfo && modelInfo.domain !== window.location.hostname) {
            isModelFromOtherSite = true
        }
        if (isModelFromOtherSite) {
            const modelPromotionDetails = promotionDetails[selectedUserId]
            if (modelPromotionDetails.promotionId) {
                promotionId = modelPromotionDetails.promotionId
                isPromotionApplied = true
            } else {
                return props.setSweetAlert({ description: 'Sorry promotion expired.' })
            }
        }

        if (isPromotionApplied === true && promotionId === false && !isModelFromOtherSite) {
            return props.setSweetAlert({ description: 'Sorry promotion expired.' })
        }
        let data = {
            messageId: id,
            amount: amount2,
            recurring: false,
            email: props.auth.user.email,
            action: 'chat',
            productName: productName,
            productSKU: productSKU,
            productCategory: title,
            promotion_id: promotionId,
            is_promotion_applied: isPromotionApplied,
            userId: props.auth.user._id
        }
        // continue from here...
        if (promotionId !== false && isPromotionApplied === true) {
            data.ribbon_text = lockedContentPromotion.ribbon_text
        }

        if (props.auth.user.isAdmin === false) {
            const userDomain = userList.filter(obj => obj._id === selectedUserId)
            data.domain = userDomain[0].domain
            data.email = props.auth.user.email
        }
        if (props.auth.user.default_payment_method === 'crypto_currency') {
            setUnlockData(data)
            unlockUsingCrypto(data)
            return
        } else {
            await props.chatPurchasePayment(data)
        }
    }

    return (
        <>
            <Button
                backgroundColor={buttonBackgroundColor || content_font_color}
                fontColor={buttonFontColor || content_color}
                style={{ fontSize: '10px', margin: '0px', minWidth: '100%', width: 'max-content', maxWidth: '270px' }}
                loading={isLoading}
                type='button'
                onClick={unlockContent}
                classes={classNames('btn-sm preview-btn mx-auto d-block mb-2', {
                    'mt-0': isPreviewAvailable
                })}
            >
                {getAmount(amount)}
            </Button>
            <div className='mb-1' style={{ fontSize: '12px', maxWidth: '270px', textAlign: 'left' }}>
                {props.auth.user.default_payment_method === 'crypto_currency' ?
                    <>
                        ${promotionAmount(amount)} will be debited from wallet balance.
                    </>
                    :
                    <>
                        ${promotionAmount(amount)} will be charged to the payment method you used previously on the website.
                    </>
                }
            </div>
            {showAddFundPopup &&
                <div className='modal fade show' role='dialog' style={{ display: 'block', backgroundColor: '#00000080' }}>
                    <div id='add-fund-form' className='modal-dialog modal-dialog-centered modal-dialog-scrollable' role='document'>
                        <div className='modal-content' style={{ backgroundColor: props.auth.appSettings.card_background_color }}>
                            <div className='modal-header align-items-center'>
                                <button className='close' onClick={() => {
                                    setShowAddFundPopup(false)
                                }} style={{ color: props.auth.appSettings.site_font_color, opacity: 1 }} />
                            </div>
                            <div className='modal-body'>
                                <div className='container'>
                                    <AddFundForm
                                        onHideAddFund={() => { setShowAddFundPopup(false) }}
                                        type='chat'
                                        transactionAmount={Number(unlockData.amount)}
                                        remainAmount={remainAmount}
                                        onCompleteTransaction={
                                            (updatedBalance) => {
                                                setShowAddFundPopup(false)
                                                if (updatedBalance) {
                                                    unlockUsingCrypto(updatedBalance)
                                                }
                                            }
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {showAlert &&
                <ConfirmSweetAlert
                    onConfirm={() => { props.setAlertLoader(true); unlockContentUsingPaymentMethod() }}
                    onCancel={() => setShowAlert(false)}
                />
            }
        </>
    )
}

UnlockMessage.propTypes = {
    message: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    chat: PropTypes.object.isRequired,
    promotion: PropTypes.object.isRequired,
    ccbill: PropTypes.object.isRequired,
    colorScheme: PropTypes.object.isRequired,
    chatPurchasePayment: PropTypes.func.isRequired,
    buttonBackgroundColor: PropTypes.string,
    buttonFontColor: PropTypes.string,
    isPreviewAvailable: PropTypes.bool,
    purchaseFromWallet: PropTypes.func.isRequired,
    updateWalletAmount: PropTypes.func.isRequired,
    setSweetAlert: PropTypes.func.isRequired,
    setConfirmSweetAlert: PropTypes.func.isRequired,
    setAlertLoader: PropTypes.func.isRequired,
    removeConfirmSweetAlert: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    chat: state.chat,
    promotion: state.promotion,
    ccbill: state.ccbill
})

export default connect(
    mapStateToProps,
    {
        chatPurchasePayment,
        purchaseFromWallet,
        updateWalletAmount,
        setSweetAlert,
        setConfirmSweetAlert,
        setAlertLoader,
        removeConfirmSweetAlert
    }
)(withRouter(UnlockMessage))
