import axios from 'axios'
import _ from 'lodash'
import { googleAnalyticsTrackEvent, setPromotionGoogleAnalyticsEvent } from '../utils/GoogleAnalyticsEvent'
import { getAppBaseUrl } from './api'
import { blogUnlockPaymentProcessing, paymentProcessing } from './ccbillActions'
import { updateRebillFailedStatus } from './ccbillSubscriptionActions'
import { updateMessageAction } from './chatActions'
import { paymentResponseReceived, updateBlogData } from './hybridPaymentAction'
import { blogUnlockPaymentResponseReceived } from './stickyIoActions'
import { SET_WALLET_AMOUNT } from './types'
import { setSweetAlert } from './sweetAlertActions'
import { awaitGetUserDetails } from './authActions'
const BASE_URL = getAppBaseUrl()

/**
 * Save ForumPay settings
 * @returns {*} error/success response of api
 */
export const getCryptoCurrencyList = (requestFrom) => async (dispatch) => {
    try {
        const res = await axios.get(`${BASE_URL}/api/crypto/get-currency-list`)
        return res.data
    } catch (error) {
        if (requestFrom !== undefined && requestFrom === 'live-stream') {
            dispatch(setSweetAlert({ description: error.response.data.message }))
        } else {
            const payload = {
                description: error.response.data.message
            }
            dispatch(setSweetAlert(payload))
        }
        return false
    }
}

/**
 * Save ForumPay settings
 * @param {*} data transaction data
 * @returns {*} error/success response of api
 */
export const startNewPayment = (data) => async () => {

    const { url, ...reqData } = data

    try {
        const res = await axios.post(`${BASE_URL}/api/crypto/${url}`, reqData)
        return res.data
    } catch (error) {
        return false
    }
}

/**
 * Save ForumPay settings
 * @param {*} data transaction data
 * @returns {*} error/success response of api
 */
export const addFund = (data) => async () => {
    try {
        const res = await axios.post(`${BASE_URL}/api/crypto/add-fund-and-payment`, data)
        return res.data
    } catch (error) {
        return error.response.data
    }
}

/**
 * Save ForumPay settings
 * @param {*} data transaction data
 * @returns {*} error/success response of api
 */
export const checkTransactionForAddFund = (data) => async () => {
    try {
        const res = await axios.post(`${BASE_URL}/api/crypto/get-transaction-status`, data)
        return res.data
    } catch (error) {
        return false
    }
}

/**
 * Save ForumPay settings
 * @param {*} data transaction data
 * @returns {*} error/success response of api
 */
export const checkTransaction = (data) => async () => {
    try {
        const res = await axios.post(`${BASE_URL}/api/crypto/check-transaction-status`, data)
        return res.data
    } catch (error) {
        return false
    }
}

/**
 * Save ForumPay settings
 * @param {*} data transaction data
 * @returns {*} error/success response of api
 */
export const getTransactionData = (data) => async (dispatch) => {
    try {
        const { type, userId, ...reqData } = data
        if (type === 'blog') {
            dispatch(blogUnlockPaymentProcessing())
        } else if (['tips', 'message'].includes(type)) {
            dispatch(paymentProcessing())
        }
        const res = await axios.post(`${BASE_URL}/api/crypto/get-transaction-info`, reqData)
        const response = res.data

        if (type === 'blog') {
            dispatch(blogUnlockPaymentResponseReceived())
            if (response.data.success === 0) {
                dispatch(setSweetAlert({ description: response.data.message }))
                return response.data
            }

            dispatch(updateBlogData(response.data.blogData))

            const isRePost = _.get(res, 'data.blogData.udid', '').length > 1
            const blogType = _.get(res, 'data.blogData.blogType', '')
            const title = _.get(res, 'data.blogData.title', '')
            const amount = _.get(res, 'data.blogData.amount', '')
            const transactionId = userId ? `${response.data.transactionId}-${userId}` : response.data.transactionId
            const productSKU = blogType === 'photo' ? 'image' : blogType
            const productCategory = isRePost === true ? 'unlock reuse feed' : 'unlock feed'
            // Set google analytics event for unlock feed
            googleAnalyticsTrackEvent('purchase', transactionId, amount, productSKU, productCategory, title)

            if (data.is_promotion_applied === true && data.promotion_id !== false) {
                setPromotionGoogleAnalyticsEvent(data.promotion_id, data.ribbon_text)
            }
        } else if (type === 'tips') {
            dispatch(paymentResponseReceived())
            const transactionId = userId !== undefined ? `${res.data.transactionId}-${userId}` : res.data.transactionId
            const productSKU = data.tipFrom
            const product = data.tipFrom === 'go live stream' ? 'go live tip' : 'tip'
            // Set google analytics event for tip
            googleAnalyticsTrackEvent('purchase', transactionId, data.amount, productSKU, product, '')
        } else if (type === 'message') {
            dispatch(paymentResponseReceived())
            dispatch(updateMessageAction(response.data.messageObject, userId))

            const transactionId = userId !== undefined ? `${response.data.transactionId}-${userId}` : response.data.transactionId
            // Set google analytics event for unlock chat
            googleAnalyticsTrackEvent('purchase', transactionId, data.amount, data.productSKU, data.productName, data.productCategory)

            if (data.promotion_id !== false) {
                setPromotionGoogleAnalyticsEvent(data.promotion_id, data.ribbon_text)
            }
        }
        return res.data
    } catch (error) {
        return false
    }
}

export const purchaseFromWallet = (data) => async (dispatch) => {
    try {
        const { userId, ...reqData } = data
        if (data.payment_for === 'blog') {
            dispatch(blogUnlockPaymentProcessing())
        } else if (data.payment_for === 'chat' || data.payment_for === 'tips') {
            dispatch(paymentProcessing())
        }
        let url = `${BASE_URL}/api/crypto/purchase-from-wallet`
        const currentUrl = window.location.hostname
        const tipFrom = _.get(data, 'tip_from', '')
        if (currentUrl !== data.domain && tipFrom !== 'menu' && data.payment_for === 'tips' && data.payment_for === 'chat') {
            url = `${BASE_URL}/api/universal-chat/purchase-from-wallet`
        }
        const res = await axios.post(url, reqData)
        const response = res.data
        if (data.payment_for === 'blog') {
            dispatch(blogUnlockPaymentResponseReceived())
            dispatch(updateBlogData(response.data.blogData))

            const isRePost = _.get(res, 'data.blogData.udid', '').length > 1
            const blogType = _.get(res, 'data.blogData.blogType', '')
            const title = _.get(res, 'data.blogData.title', '')
            const amount = _.get(res, 'data.blogData.amount', '')
            const transactionId = userId ? `${response.data.transactionId}-${userId}` : response.data.transactionId
            const productSKU = blogType === 'photo' ? 'image' : blogType
            const productCategory = isRePost === true ? 'unlock reuse feed' : 'unlock feed'
            // Set google analytics event for unlock feed
            googleAnalyticsTrackEvent('purchase', transactionId, amount, productSKU, productCategory, title)

            if (data.is_promotion_applied === true && data.promotion_id !== false) {
                setPromotionGoogleAnalyticsEvent(data.promotion_id, data.ribbon_text)
            }
        } else if (data.payment_for === 'chat') {
            dispatch(paymentResponseReceived())
            let senderId = currentUrl !== data.domain ? response.data.messageObject.senderId : userId
            dispatch(updateMessageAction(response.data.messageObject, senderId))

            const transactionId = userId !== undefined ? `${response.data.transactionId}-${userId}` : response.data.transactionId
            // Set google analytics event for unlock chat
            if (currentUrl === data.domain) {
                googleAnalyticsTrackEvent('purchase', transactionId, data.amount, data.productSKU, data.productName, data.productCategory)
            }

            if (data.promotion_id !== false) {
                setPromotionGoogleAnalyticsEvent(data.promotion_id, data.ribbon_text)
            }
        } else if (data.payment_for === 'tips') {
            dispatch(paymentResponseReceived())
        }
        return res.data
    } catch (error) {
        if (data.payment_for === 'blog') {
            dispatch(blogUnlockPaymentResponseReceived())
        } else if (data.payment_for === 'chat') {
            dispatch(paymentResponseReceived())
        }
        return error.response.data
    }
}

/**
 * @description cancelSubscription
 */
export const cancelUserSubscriptionForCrypto = (data = { cancellation_reason: '' }) => async (dispatch) => {
    const url = `${BASE_URL}/api/crypto/cancel-subscription`
    try {
        const res = await axios.post(url, data)
        const response = res.data
        const payload = {
            description: 'There was a problem cancelling your subscription. Please try again after an hour.'
        }

        if (response.data.cancelled === true) {
            payload.description = 'Subscription Cancelled'
            updateRebillFailedStatus()
            await dispatch(awaitGetUserDetails(false))
        }
        dispatch(setSweetAlert(payload))
    } catch (error) {
        const errorMessage = _.get(error, 'response.data.message', 'Invalid Request')
        const payload = {
            description: errorMessage
        }
        dispatch(setSweetAlert(payload))
        return
    }
}

/**
 * Save ForumPay settings
 * @param {*} data transaction data
 * @returns {*} error/success response of api
 */
export const getWalletHistory = (data) => async () => {
    try {
        const res = await axios.post(`${BASE_URL}/api/crypto/wallet-history`, data)
        return res.data
    } catch (error) {
        return false
    }
}

export const getTransactionInfo = (data) => async () => {
    const res = await axios.post(`${BASE_URL}/api/crypto/get-transaction-details`, data)
    return res.data
}

export const generatePaymentId = (data) => async () => {
    try {
        const res = await axios.post(`${BASE_URL}/api/crypto/generate-payment-id`, data)
        return res.data
    } catch (error) {
        return error.response.data
    }
}

export const updateWalletAmount = (data) => {
    return {
        type: SET_WALLET_AMOUNT,
        payload: data
    }
}
