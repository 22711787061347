import React, { useEffect, useState, useRef, useCallback } from 'react'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import crypto from 'crypto'
import _ from 'lodash'
import socket from '../../utils/socket'
import {
    chatUserProfileInfoAction,
    setChatId,
    setSelectedChatModelId,
    readMessage,
    deleteMessageAction,
    addNewMessageInEnd,
    showBootstrapNavbar,
    getUserSendMessageStatus,
    setAllowUserToChat,
    updateMessageAction,
    deleteAllMessageAction,
    sendTextMessage,
    updateUsersLastMessage
} from '../../actions/chatActions'
import { openCopyToClipboardToast } from '../../actions/copyToClipboardAction'
import {
    addToken,
    removeToken,
    setStreamLoader,
    setStartStream,
    setIsLive,
    setStreamJoined,
    setStreamToken,
    setCanModelGoLive,
    setServerTabToken,
    setRoom,
    setPubId,
    setPubPvtId,
    setSendGoLiveStreamMessage,
    setLiveStreamStatus,
    startLiveStream
} from '../../actions/streamAction'
import { setSweetAlert } from '../../actions/sweetAlertActions'
import styled from 'styled-components'
import { getCloudFrontAssetsUrl } from '../../utils/assets'
import { ROLE_MODEL, ROLE_CONTENT_MANAGER, ROLE_USER } from '../../utils/constant'

const HelperButton = styled.span`
    border-radius: 25px;
    border: none;
    font-size: 20px;
    vertical-align: middle;
    padding-bottom: 2px;
    padding-left: 5px;
    cursor: pointer;
    background-color: transparent;
    color: ${props => props.contentColor};
`

const VideoButton = styled.span`
    margin: 0px;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;

    .fa-video {
        font-size: 24px;
        color: ${props => props.contentColor};
    }
`

const Icon = styled.div`
    width: 40px;
    height: 40px;
    background-color: ${props => props.color};
    mask: url(${props => props.src});
    -webkit-mask: url(${props => props.src}) no-repeat center / contain;
`

const LiveStream = (props) => {
    const [helperModelOpen, setHelperModelOpen] = useState(false)
    const {
        auth,
        liveStream,
        chat
    } = props
    const {
        isLoading,
        startStream,
        isLive,
        streamJoined,
        serverTabToken,
        sendGoLiveStreamMessage,
        liveStreamStatus
    } = liveStream
    const { role } = props.auth.user
    const { content_color, enable_live_stream_report_for_content_manager, chat_header_font_color } = props.auth.appSettings
    const tabToken = useRef(crypto.randomBytes(6).toString('hex')).current
    const cleanup = useRef(null)
    const receiverId = chat.selectedUserId
    const receiverProfileDetails = (chat.userProfileInfo && chat.userProfileInfo[receiverId])
        ? chat.userProfileInfo[receiverId]
        : getCloudFrontAssetsUrl('faces/avatar.png')
    const showViewGoLiveButton = receiverProfileDetails?.isLiveStreamAvailable === true && (['admin', 'sub_admin'].includes(role)) ? true : role === 'content_manager' && enable_live_stream_report_for_content_manager === true ? true : false

    useEffect(() => {
            if (chat.selectedUserId === '') {
                return props.history.push('/admin/chat')
            }

            let roomId = ''
            if (props.match.params.id === undefined) {
                roomId = auth.user._id
            } else {
                roomId = props.match.params.id
            }
            const roomData = {
                roomId,
                userId: auth.user._id
            }

            if (auth.user.isAdmin === true) {
                socket.emit('GET_ONLINE_USER_LIST')
                const data = { adminId: auth.user._id, userId: props.match.params.id }
                socket.emit('ADMIN_IN_USER_CHAT', data)
            }
            if (typeof auth.user._id !== 'undefined' && auth.user.isAdmin === false && auth.user.role !== 'proxy_user') {
                socket.emit('USER_ONLINE',  { userId: auth.user._id })
            }
            socket.emit('JOIN_ROOM', roomData)
            receiveMessage()
            socket.on('connect', () => {
                socket.emit('JOIN_ROOM', roomData)
            })
            updateMessage()
            deleteMessage()
            if (auth.user.isAdmin === false) {
                updateSendMessageStatusForUser()
            }
            onGoLive()
            onStopLive()
            onStreamStarted()
            onLeftStream()
            onJoinLiveStream()
            onStreamAlreadyStarted()
            const cleanup = () => {
                socket.emit('LEAVE_ROOM', roomData)
                socket.removeListener('MESSAGE_RECEIVE')
                socket.removeListener('UPDATE_MESSAGE')
                socket.removeListener('DELETE_MESSAGE')
                if (auth.user.isAdmin === false) {
                    socket.removeListener('UPDATE_SEND_MESSAGE_STATUS')
                }
                socket.removeListener('LIVE_STARTED')
                socket.removeListener('USER_LEFT_STREAM')
                socket.removeListener('GO_LIVE')
                socket.removeListener('STOP_LIVE')
                socket.removeListener('USER_JOIN_STREAM')
                socket.removeListener('STREAM_ALREADY_STARTED')
                socket.removeListener('ONLINE_USER_LIST')
                socket.removeListener('USER_OFFLINE')
            }

            if (chat.userProfileInfo[auth.user._id] === undefined) {
                props.chatUserProfileInfoAction(auth.user._id)
            }

            if (chat.selectedUserId !== '' || props.match.params.id !== undefined) {
                if (props.match.params.id === undefined) {
                    if (chat.userProfileInfo[chat.selectedUserId] === undefined) {
                        props.chatUserProfileInfoAction(chat.selectedUserId)
                    }
                } else {
                    if (chat.userProfileInfo[props.match.params.id] === undefined) {
                        props.chatUserProfileInfoAction(props.match.params.id)
                        props.setChatId(props.match.params.id, props.history)
                        props.setSelectedChatModelId(auth.appSettings.model_id, props.history)
                    }
                }
            }

            if (chat.userProfileInfo[auth.appSettings.model_id] === undefined) {
                props.chatUserProfileInfoAction(auth.appSettings.model_id)
            }
            const appSettings = auth.appSettings
            if (auth.user.isAdmin === true && appSettings.chat_booking_enabled && appSettings.chat_settings.disabled_normal_chat === true) {
                props.getUserSendMessageStatus()
            }
            const { enable_live_streaming } = props.auth.appSettings

            if (auth.user.isAdmin === false && enable_live_streaming === true && socket.isSocketConnected === true) {
                socket.emit('CHECK_STREAM_STARTED', auth.user._id)
            }

            if ((auth.user.role === 'model' || auth.user.role === 'live_stream_manager') && enable_live_streaming === true) {
                const data = {
                    userId: roomId
                }
                props.addToken(data, (res) => {
                    if (res.data.janus === 'success') {
                        props.setStreamToken(res.data.streamToken)
                    }
                })
            }

            const onUnmount = () => {
                cleanup() // Perform the cleanup actions
                window.removeEventListener('beforeunload', onUnmount)
            }

            window.addEventListener('beforeunload', onUnmount, false)

            const { isAuthenticated, isAdmin } = auth

            if (isAuthenticated && (window.orientation !== undefined)) {
                let showProgressiveWebPopUp = localStorage.getItem('showProgressiveWebPopUp')
                if (showProgressiveWebPopUp === 'never') {
                    // DO NOTHING
                } else if (!showProgressiveWebPopUp) {
                    localStorage.setItem('showProgressiveWebPopUp', 1)
                } else if (showProgressiveWebPopUp !== 'false' && Number(showProgressiveWebPopUp) < 50) {
                    showProgressiveWebPopUp = Number(showProgressiveWebPopUp) + 1
                    localStorage.setItem('showProgressiveWebPopUp', showProgressiveWebPopUp)
                }
            }

            if (isAuthenticated && isAdmin === false) {
                const differentPasswordPopupReminderCount = localStorage.getItem('differentPasswordPopupReminderCount')
                if (differentPasswordPopupReminderCount && differentPasswordPopupReminderCount <= 50) {
                    localStorage.setItem('differentPasswordPopupReminderCount', Number(differentPasswordPopupReminderCount) + 1)
                }
            }

            window.addEventListener('beforeunload', onUnmount, false)

            // Return the cleanup function in useEffect
            return () => {
                onUnmount() // Call onUnmount to remove listeners and clean up on component unmount
            }
    }, [isLive])

    const onUnmount = useCallback(() => {
        const { enable_live_streaming } = auth.appSettings
        if (enable_live_streaming) {
            if ((auth.user.role === 'model' || auth.role === 'live_stream_manager') && tabToken === serverTabToken && sendGoLiveStreamMessage) {
                props.removeToken()

                const liveStreamMessageObject = {
                    sender: chat.selectedModelId,
                    receiver: chat.selectedUserId,
                    type: 'GO_LIVE_STREAM',
                    fromAdmin: true,
                    message: '',
                    userId: chat.selectedUserId
                }
                props.sendTextMessage(liveStreamMessageObject, () => { })

                const messageObject = {
                    sender: chat.selectedModelId,
                    receiver: chat.selectedUserId,
                    type: 'system',
                    fromAdmin: true,
                    message: 'stop',
                    userId: chat.selectedUserId
                }
                props.sendTextMessage(messageObject, () => {
                    scrollToBottom()
                    props.setIsLive(false)
                    props.setStreamJoined(false)
                })
            }
            if (!auth.user.isAdmin && streamJoined) {
                const messageObject = {
                    type: 'system',
                    fromAdmin: false,
                    message: 'leave',
                    receiver: chat.selectedUserId,
                    userId: chat.selectedUserId
                }
                props.sendTextMessage(messageObject, () => { })
            }
        }
    }, [tabToken, serverTabToken, sendGoLiveStreamMessage, isLive])

    useEffect(() => {
        // Add 'beforeunload' event listener for page refresh/close
        window.addEventListener('beforeunload', onUnmount)

        // Cleanup on component unmount
        return () => {
            onUnmount()
            window.removeEventListener('beforeunload', onUnmount)

            if (cleanup.current) cleanup.current()
            cleanup.current = null
        }
    }, [onUnmount])

    const deleteMessage = () => {
        socket.on('DELETE_MESSAGE', (msg) => {
            props.deleteMessageAction(msg._id, props.chat.selectedUserId)
        })
    }

    const updateSendMessageStatusForUser = () => {
        if (auth.user.isAdmin === false) {
            socket.on('UPDATE_SEND_MESSAGE_STATUS', () => {
                // setCanChat(status)
            })
        }
    }

    const onLeftStream = () => {
        socket.on('USER_LEFT_STREAM', () => {
            if (auth.user.isAdmin === true) {
                props.openCopyToClipboardToast('STREAM')
            } else {
                const messageObject = {
                    type: 'system',
                    fromAdmin: false,
                    message: 'leave',
                    receiver: chat.selectedUserId,
                    userId: chat.selectedUserId
                }
                props.sendTextMessage(messageObject, () => {
                    scrollToBottom()
                })
            }
        })
    }

    const onJoinLiveStream = () => {
        socket.on('USER_JOIN_STREAM', () => {
            if (auth.user.isAdmin === true) {
                props.openCopyToClipboardToast('STREAM')
            } else {
                const messageObject = {
                    type: 'system',
                    fromAdmin: false,
                    message: 'join',
                    receiver: chat.selectedUserId,
                    userId: chat.selectedUserId
                }
                props.sendTextMessage(messageObject, () => {
                    scrollToBottom()
                })
            }
        })
    }

    const onStreamAlreadyStarted = () => {
        socket.on('STREAM_ALREADY_STARTED', () => {
            if (auth.user.isAdmin === true) {
                props.setSweetAlert('Stream already started with another user.')
                props.setCanModelGoLive({ canModelGoLive: false })
            }
        })
    }

    const onGoLive = () => {
        props.setSendGoLiveStreamMessage(true)
        socket.on('GO_LIVE', () => {
            props.setIsLive(true)
        })
    }

    const onStopLive = () => {
        props.setSendGoLiveStreamMessage(false)
        socket.on('STOP_LIVE', () => {
            props.setIsLive(false)
            props.setStreamJoined(false)
        })
    }

    const receiveMessage = () => {
        socket.on('MESSAGE_RECEIVE', (msg) => {
            const { isAdmin, _id, role } = auth.user
            const userIdString = _id.toString()
            const { selectedUserId, selectedModelId } = props.chat
            const { receiverId, senderId } = msg
            const receiverIdString = receiverId.toString()
            const senderIdString = senderId.toString()

            let data = {
                userId: selectedUserId
            }
            if ((isAdmin)) {
                data.modelId = props.auth.appSettings.model_id ? props.auth.appSettings.model_id : selectedModelId
            }
            const userId = msg.fromAdmin === true ? receiverId : senderId
            // add message from user while message sender is user
            addMessage(msg, userId)

            const lastMessage = msg.type === 'tips'
                ? isAdmin === true ? `<user_name> just tipped you ${msg.message}!` : `You just tipped <model_name> ${msg.message}`
                : msg.type === 'GO_LIVE_STREAM' && msg.message === '' ? 'Go Live Stream with <user_name> has ended.' : msg.message
            if (msg.type !== 'system') {
                props.updateUsersLastMessage({ user_id: userId, message: lastMessage, type: msg.type, isAdmin: msg.fromAdmin })
            }

            let shouldReadMessage = false
            // All admin should read the message if the current user send a message while an admin is present in the user chat
            if (isAdmin && [receiverIdString, senderIdString].includes(selectedUserId)) {
                shouldReadMessage = true
            }

            // When a message is sent or received by the user, it indicates that the user has read the message.
            if (isAdmin === false && [receiverIdString, senderIdString].includes(userIdString)) {
                shouldReadMessage = true
            }

            if ([ROLE_MODEL, ROLE_CONTENT_MANAGER, ROLE_USER].includes(role) && shouldReadMessage === true) {
                props.readMessage(data, isAdmin)
            }
        })
    }

    const addMessage = (message, userId) => {
        if (message && !message.isRotated) {
            props.addNewMessageInEnd(message, userId)
            scrollToBottom()
        }
    }

    const scrollToBottomMessages = () => {
        // get the messageLiveList div and set the scrollTop to the height of the div
        const objDiv = document.getElementById('message-list')
        if (objDiv) {
            objDiv.scrollTop = objDiv.scrollHeight
        }
    }

    const updateMessage = () => {
        socket.on('UPDATE_MESSAGE', (msg) => {
            const messageId = _.get(msg, 'messageId', false)
            if (messageId !== false) {
                msg._id = messageId
            }
            props.updateMessageAction(msg, props.chat.selectedUserId)
        })
    }

    const onStreamStarted = () => {
        socket.on('LIVE_STARTED', (streamData) => {
            if (streamData === null) {
                props.setStreamJoined(false)
                props.setIsLive(false)
                return
            }
            if (streamData.streamToken !== undefined && streamData.room !== undefined) {
                const isLiveStreamStarted = _.get(streamData, 'isLiveStreamStarted', false)
                props.setRoom({ room: streamData.room })
                props.setPubId({ pubId: streamData.pubId })
                props.setPubPvtId({ pubPvtId: streamData.pubPvtId })
                props.setStreamToken({ streamToken: streamData.streamToken })
                props.setIsLive(isLiveStreamStarted)
                if (isLiveStreamStarted === true) {
                    if (auth.user.isAdmin === false) {
                        const messageObject = {
                            type: 'system',
                            fromAdmin: false,
                            message: 'join',
                            receiver: chat.selectedUserId,
                            userId: chat.selectedUserId
                        }
                        props.sendTextMessage(messageObject, () => {
                            props.setStreamJoined(true)
                        })
                    } else {
                        if (streamData.tabToken === tabToken) {
                            props.setCanModelGoLive(true)
                            props.setServerTabToken(streamData.tabToken)
                        } else {
                            props.setCanModelGoLive(false)
                            props.setServerTabToken(streamData.tabToken)
                        }
                    }
                } else {
                    if (auth.user.isAdmin === false) {
                        props.setStreamJoined(false)
                        props.setIsLive(false)
                        const messageObject = {
                            type: 'system',
                            fromAdmin: false,
                            message: 'leave',
                            receiver: chat.selectedUserId,
                            userId: chat.selectedUserId
                        }
                        props.sendTextMessage(messageObject, () => {
                            props.setStreamJoined(false)
                            props.setIsLive(false)
                        })
                    } else {
                        props.setCanModelGoLive(true)
                    }
                }
                if (auth.user.isAdmin === true) {
                    if (streamData.isLiveStreamStarted === true && auth.user.isAdmin === true) {
                        props.openCopyToClipboardToast('STREAM')
                    }
                }
            }
        })
    }

    const scrollToBottom = () => {
        // get the messageLiveList div and set the scrollTop to the height of the div
        const messageId = isLive === true ? 'messageLiveList' : 'message-list'
        const objDiv = document.getElementById(messageId)
        if (objDiv) {
            objDiv.scrollTop = objDiv.scrollHeight
        }
    }

    return (
        <>
            {
                (role === 'model' || role === 'live_stream_manager') && !isLoading && liveStreamStatus !== 'live' &&
                <>
                    <VideoButton
                        onClick={() => { props.startLiveStream(true); props.setStartStream(); props.setStreamLoader(true); props.showBootstrapNavbar(true) }}
                        className='btn shadow-none'
                        contentColor={chat_header_font_color || content_color}>
                        <i className='fas fa-video'></i>
                    </VideoButton>
                    <HelperButton
                        onClick={() => { setHelperModelOpen(true); props.showBootstrapNavbar(true) }}
                        contentColor={chat_header_font_color || content_color}>
                        <i className='fas fa-question-circle'></i>
                    </HelperButton>
                </>
            }
            {['admin', 'content_manager'].includes(role) && showViewGoLiveButton === true &&
                <Link target='_blank' rel='noreferrer' to={`/admin/live-stream-sessions/${receiverId}`}>
                    <Icon
                        src={getCloudFrontAssetsUrl('images/video-history.png')}
                        color={chat_header_font_color || content_color} />
                </Link>
            }
            {helperModelOpen &&
                <div className='modal fade show' role='dialog' style={{ display: 'block', backgroundColor: '#00000080', zIndex: '5' }}>
                    <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg' role='document'>
                        <div className='modal-content' style={{ color: props.auth.appSettings.site_font_color }}>
                            <div className='modal-header' style={{ backgroundColor: props.auth.appSettings.card_background_color }}>
                                <h5 className='modal-title'>Go Live Stream</h5>
                                <button className='close' onClick={() => { setHelperModelOpen(false); props.showBootstrapNavbar(false) }} style={{ color: props.auth.appSettings.site_font_color, opacity: 1 }} />
                            </div>
                            <div className='modal-body text-left' style={{ backgroundColor: props.auth.appSettings.card_background_color }}>
                                <b>STARTING GO LIVE & TIPPING</b>
                                <p>
                                    Pressing the GO LIVE button will immediately start a video session with the subscriber. The subscriber must be in chat to see you.
                                    We suggest planning this with the subscriber and in most cases you’ll want to agree on a price and length of the session.
                                    Most influencers will want to receive a tip for the agreed upon amount prior to the session but this is completely optional.
                                    The subscriber can also leave tips during and after the session.
                                </p>
                                <b>
                                    GO LIVE VIEW
                                </b>
                                <p>
                                    The Go Live feature looks and works very similar to Instagram Go Live. The subscriber can see you, but there is no video for the subscriber.
                                    They can chat with you during the session. The chat is shown over the video and takes up a small amount of the screen.
                                    For the subscriber, the chat will only be shown on the bottom 3rd of the screen, and in your view the chat will be shown on the bottom half of the screen.
                                    Subscribers can unlock previous locked content during the session so you are encouraged to ask the subscriber to unlock previous content.
                                </p>
                                <b>
                                    SOUND IN GO LIVE
                                </b>
                                <p>
                                    When GO LIVE begins, the sound is set to off for the subscriber for privacy reasons but the subscriber is prompted to press the sound icon at the top of their screen to toggle sound on.
                                    You have a microphone icon at the top of your screen if you want to mute your sound.
                                </p>
                                <b>
                                    SUBSCRIBER CAN LEAVE AND RETURN
                                </b>
                                <p>
                                    If the subscriber wants to temporarily leave chat to check other content sections (for example, to upload content outside of Go Live, or to view/purchase content in their normal feed) they can do so by hitting the X on the top-right of the chat screen.
                                    You will be notified that they left. The subscriber can return to Go Live by pressing a JOIN button at the top of their screen.
                                </p>
                                <b>
                                    ENDING GO LIVE
                                </b>
                                <p>
                                    You can end the session at any time by pressing the X on the top-right of your screen.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

LiveStream.propTypes = {
    auth: PropTypes.object.isRequired,
    chat: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    liveStream: PropTypes.object.isRequired,
    chatUserProfileInfoAction: PropTypes.func.isRequired,
    setChatId: PropTypes.func.isRequired,
    setSelectedChatModelId: PropTypes.func.isRequired,
    addToken: PropTypes.func.isRequired,
    removeToken: PropTypes.func.isRequired,
    readMessage: PropTypes.func.isRequired,
    openCopyToClipboardToast: PropTypes.func.isRequired,
    setSweetAlert: PropTypes.func.isRequired,
    deleteMessageAction: PropTypes.func.isRequired,
    addNewMessageInEnd: PropTypes.func.isRequired,
    showBootstrapNavbar: PropTypes.func.isRequired,
    getUserSendMessageStatus: PropTypes.func.isRequired,
    setAllowUserToChat: PropTypes.func.isRequired,
    receiverProfileDetails: PropTypes.object.isRequired,
    receiverId: PropTypes.string.isRequired,
    handleTextMessageSend: PropTypes.func.isRequired,
    scrollToBottom: PropTypes.func.isRequired,
    setCanChat: PropTypes.func.isRequired,
    loadSocketEvent: PropTypes.func.isRequired,
    updateMessageAction: PropTypes.func.isRequired,
    deleteAllMessageAction: PropTypes.func.isRequired,
    setStreamLoader: PropTypes.func.isRequired,
    setStartStream: PropTypes.func.isRequired,
    setIsLive: PropTypes.func.isRequired,
    setStreamJoined: PropTypes.func.isRequired,
    setStreamToken: PropTypes.func.isRequired,
    setCanModelGoLive: PropTypes.func.isRequired,
    setServerTabToken: PropTypes.func.isRequired,
    setRoom: PropTypes.func.isRequired,
    setPubId: PropTypes.func.isRequired,
    setPubPvtId: PropTypes.func.isRequired,
    setSendGoLiveStreamMessage: PropTypes.func.isRequired,
    setLiveStreamStatus: PropTypes.func.isRequired,
    startLiveStream: PropTypes.func.isRequired,
    sendTextMessage: PropTypes.func.isRequired,
    updateUsersLastMessage: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    chat: state.chat,
    liveStream: state.liveStream
})

export default connect(
    mapStateToProps,
    {
        chatUserProfileInfoAction,
        setChatId,
        setSelectedChatModelId,
        addToken,
        removeToken,
        readMessage,
        openCopyToClipboardToast,
        setSweetAlert,
        deleteMessageAction,
        addNewMessageInEnd,
        showBootstrapNavbar,
        getUserSendMessageStatus,
        setAllowUserToChat,
        deleteAllMessageAction,
        updateMessageAction,
        setStreamLoader,
        setStartStream,
        setIsLive,
        setStreamJoined,
        setStreamToken,
        setCanModelGoLive,
        setServerTabToken,
        setRoom,
        setPubId,
        setPubPvtId,
        setSendGoLiveStreamMessage,
        setLiveStreamStatus,
        startLiveStream,
        sendTextMessage,
        updateUsersLastMessage
    }
)(withRouter(LiveStream))
